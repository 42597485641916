// import FinancialStatements from 'pages/FinancialStatements'
// import React, { useEffect, useRef, useState } from 'react'

// const ScrollLeftAndRight = ({ excelRead, isLoading, setIsLoading }) => {
//   const scrollImagesRef = useRef(null)
//   const [scrollLength, setScrollLength] = useState(0)
//   console.log('scrollLength', scrollLength)
//   console.log('scrollImagesRef', scrollImagesRef)

//   useEffect(() => {
//     const scrollImages = scrollImagesRef.current
//     if (scrollImages) {
//       const newScrollLength =
//         scrollImages.scrollWidth - scrollImages.clientWidth
//       console.log('Scroll width:', scrollImages.scrollWidth)
//       console.log('Client width:', scrollImages.clientWidth)
//       console.log('New scroll length:', newScrollLength)
//       setScrollLength(newScrollLength)
//       checkScroll() // check scroll after setting scroll length
//     }
//   }, [])

//   const checkScroll = () => {
//     const scrollImages = scrollImagesRef.current
//     if (scrollImages) {
//       const currentScroll = scrollImages.scrollLeft
//       const leftButton = document.querySelector('.left')
//       const rightButton = document.querySelector('.right')
//       const currentScrollLength =
//         scrollImages.scrollWidth - scrollImages.clientWidth
//       console.log('currentScroll:', currentScroll)
//       console.log('currentScrollLength:', currentScrollLength)

//       // Define a tolerance threshold
//       const tolerance = 1 // You can adjust this tolerance as needed

//       // Check if currentScroll is within tolerance of currentScrollLength
//       if (currentScroll <= 0) {
//         leftButton.setAttribute('disabled', 'true')
//         rightButton.removeAttribute('disabled')
//       } else if (currentScroll >= currentScrollLength - tolerance) {
//         rightButton.setAttribute('disabled', 'true')
//         leftButton.removeAttribute('disabled')
//       } else {
//         leftButton.removeAttribute('disabled')
//         rightButton.removeAttribute('disabled')
//       }
//     }
//   }
//   const leftScroll = () => {
//     const scrollImages = scrollImagesRef.current
//     if (scrollImages) {
//       scrollImages.scrollTo({
//         left: scrollImages.scrollLeft - 200,
//         behavior: 'smooth',
//       })
//       console.log('Left scroll clicked.')
//       // Check scroll position after a delay
//       setTimeout(() => {
//         checkScroll()
//       }, 300) // Adjust delay as needed
//     }
//   }

//   const rightScroll = () => {
//     const scrollImages = scrollImagesRef.current
//     if (scrollImages) {
//       scrollImages.scrollTo({
//         left: scrollImages.scrollLeft + 200,
//         behavior: 'smooth',
//       })
//       console.log('Right scroll clicked.')
//       // Check scroll position after a delay
//       setTimeout(() => {
//         checkScroll()
//       }, 300) // Adjust delay as needed
//     }
//   }

//   return (
//     <div className="cover">
//       <button className="left" onClick={leftScroll}>
//         <i className="fas fa-angle-double-left"></i>
//       </button>
//       <div className="scroll-images" ref={scrollImagesRef}>
//         <FinancialStatements
//           excelRead={excelRead}
//           isLoading={isLoading}
//           setIsLoading={setIsLoading}
//         />
//       </div>
//       <button className="right" onClick={rightScroll}>
//         <i className="fas fa-angle-double-right"></i>
//       </button>
//     </div>
//   )
// }

// export default ScrollLeftAndRight


import React, { useEffect, useRef, useState } from 'react';

const ScrollLeftAndRight = ({ excelRead, isLoading, setIsLoading, Component }) => {
  const scrollImagesRef = useRef(null);
  const [scrollLength, setScrollLength] = useState(0);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  console.log("viewportWidth",viewportWidth)

  useEffect(() => {
    const scrollImages = scrollImagesRef.current;
    if (scrollImages) {
      const newScrollLength = scrollImages.scrollWidth - scrollImages.clientWidth;
      setScrollLength(newScrollLength);
      checkScroll();
    }
  }, []);

  const checkScroll = () => {
    const scrollImages = scrollImagesRef.current;
    if (scrollImages) {
      const currentScroll = scrollImages.scrollLeft;
      const leftButton = document.querySelector('.left');
      const rightButton = document.querySelector('.right');
      const currentScrollLength = scrollImages.scrollWidth - scrollImages.clientWidth;

      const tolerance = 1;

      if (currentScroll <= 0) {
        leftButton.setAttribute('disabled', 'true');
        rightButton.removeAttribute('disabled');
      } else if (currentScroll >= currentScrollLength - tolerance) {
        rightButton.setAttribute('disabled', 'true');
        leftButton.removeAttribute('disabled');
      } else {
        leftButton.removeAttribute('disabled');
        rightButton.removeAttribute('disabled');
      }
    }
  };

  const leftScroll = () => {
    const scrollImages = scrollImagesRef.current;
    if (scrollImages) {
      scrollImages.scrollTo({
        left: scrollImages.scrollLeft - 200,
        behavior: 'smooth',
      });
      setTimeout(() => {
        checkScroll();
      }, 300);
    }
  };

  const rightScroll = () => {
    const scrollImages = scrollImagesRef.current;
    if (scrollImages) {
      scrollImages.scrollTo({
        left: scrollImages.scrollLeft + 200,
        behavior: 'smooth',
      });
      setTimeout(() => {
        checkScroll();
      }, 300);
    }
  };

  return (
    <div className="cover">
      <button className="left" onClick={leftScroll}>
        <i className="fas fa-angle-double-left"></i>
      </button>
      <div className="scroll-images" ref={scrollImagesRef}>
        {Component}
      </div>
      <button className="right" onClick={rightScroll}>
        <i className="fas fa-angle-double-right"></i>
      </button>
    </div>
  );
};

export default ScrollLeftAndRight;
