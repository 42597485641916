// import * as React from 'react'
// import Box from '@mui/material/Box'
// import Card from '@mui/material/Card'
// import Container from '@mui/material/Container'
// import Grid from '@mui/material/Grid'
// import Stack from '@mui/material/Stack'
// import Typography from '@mui/material/Typography'
// import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded'
// import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded'
// import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded'
// import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded'
// import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded'
// import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded'
// import financialVideo from '../assets/videos/financialVideo.mp4'
// import Business from '../assets/images/business.png'
// import Cash from '../assets/images/cash.png'
// import Budget from '../assets/images/budget.png'
// import Risk from '../assets/images/risk.png'
// import Contingency from '../assets/images/contingency.png'
// import Equity from '../assets/images/equity.png'

// const items = [
//   {
//     icon: <SettingsSuggestRoundedIcon />,
//     title: 'Business planning',
//     description:
//       'Short- and medium-term financial projections in the business plan that can help the company attract attention from investors.',
//     image: Business,
//   },

//   {
//     icon: <ConstructionRoundedIcon />,
//     title: 'Budget planning',
//     description:
//       'Financial projections help the management anticipate future cash flow by estimating the amount of money that may be available.',
//     image: Cash,
//   },
//   {
//     icon: <ThumbUpAltRoundedIcon />,
//     title: 'Cash flow analysis',
//     description:
//       'Financial projections help the management anticipate future cash flow by estimating the amount of money that may be available.',
//     image: Budget,
//   },
//   {
//     icon: <AutoFixHighRoundedIcon />,
//     title: 'Risk identification and assessment',
//     description:
//       "Financial projections help the management identify risks and assess the realization of potential risks on the company's performance.",
//     image: Risk,
//   },
//   {
//     icon: <SupportAgentRoundedIcon />,
//     title: 'Contingency planning',
//     description:
//       'The management can use financial projections to develop contingency planning against unexpected risks.',
//     image: Contingency,
//   },
//   {
//     icon: <QueryStatsRoundedIcon />,
//     title: 'Measuring company equity valuation and credit risk',
//     description:
//       'The management and investors use financial projections to estimate credit rating and company valuation as part of investment decisions.',
//     image: Equity,
//   },
// ]

// export default function Highlights() {
//   return (
//     <Box
//       id="highlights"
//       sx={{
//         pt: { xs: 4, sm: 12 },
//         pb: { xs: 8, sm: 12 },
//         mt: { xs: 4, sm: 12 },
//         mb: { xs: 8, sm: 12 },
//         color: 'white',
//         bgcolor: '#06090a',
//         margin: '0 auto',
//       }}
//     >
//       <Container
//         sx={{
//           position: 'relative',
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           gap: { xs: 3, sm: 6 },
//         }}
//       >
//         <Box
//           sx={{
//             width: { sm: '100%', md: '100%' },
//             textAlign: { sm: 'left', md: 'center' },
//           }}
//         >
//           <video
//             // style={{
//             //   ...videoStyle,
//             //   width: window.innerWidth <= 768 ? '100%' : videoStyle.width,s
//             // }}
//             autoPlay
//             muted
//             playsInline
//             // id="homeHeaderVideoFinancial"
//             onError={(e) => console.error('Video Error:', e)}
//             // onLoadedData={handleVideoLoad}
//             // className="home-container-header-video-in"
//           >
//             <source src={financialVideo} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </Box>
//         <Grid container spacing={2.5}>
//           {items.map((item, index) => (
//             <Grid item xs={12} sm={6} md={4} key={index}>
//               <Stack
//                 direction="column"
//                 color="inherit"
//                 component={Card}
//                 spacing={1}
//                 useFlexGap
//                 sx={{
//                   p: 3,
//                   height: '100%',
//                   border: '1px solid',
//                   borderColor: 'grey.800',
//                   background: 'transparent',
//                   backgroundColor: 'grey.900',
//                   textAlign: 'center',
//                 }}
//               >
//                 <img
//                   src={item.image}
//                   alt="SVG"
//                   style={{ width: '100%', height: 'auto' }}
//                 />
//                 {/* <Box sx={{ opacity: '50%' }}>{item.icon}</Box> */}
//                 <div>
//                   <Typography fontWeight="medium" gutterBottom>
//                     {item.title}
//                   </Typography>
//                   <Typography variant="body2" sx={{ color: 'grey.400' }}>
//                     {item.description}
//                   </Typography>
//                 </div>
//               </Stack>
//             </Grid>
//           ))}
//         </Grid>
//       </Container>
//     </Box>
//   )
// }
import * as React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded'
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded'
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded'
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded'
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded'
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded'
import financialVideo from '../assets/videos/financialVideo.mp4'
import Business from '../assets/images/business.png'
import Cash from '../assets/images/cash.png'
import Budget from '../assets/images/budget.png'
import Risk from '../assets/images/risk.png'
import Contingency from '../assets/images/contingency.png'
import Equity from '../assets/images/equity.png'
import { useIntl } from 'react-intl'

export default function Highlights() {
  const intl = useIntl()

  const items = [
    {
      icon: <SettingsSuggestRoundedIcon />,
      title: intl.formatMessage({ id: 'HOME.CARD.TITLE.BUSINESS' }),
      description: intl.formatMessage({ id: 'HOME.CARD.TEXT.BUSINESS' }),
      image: Business,
    },
    {
      icon: <ConstructionRoundedIcon />,
      title: intl.formatMessage({ id: 'HOME.CARD.TITLE.BUTGET' }),
      description: intl.formatMessage({ id: 'HOME.CARD.TEXT.BUTGET' }),
      image: Cash,
    },
    {
      icon: <ThumbUpAltRoundedIcon />,
      title: intl.formatMessage({ id: 'HOME.CARD.TITLE.CASH' }),
      description: intl.formatMessage({ id: 'HOME.CARD.TEXT.CASH' }),
      image: Budget,
    },
    {
      icon: <AutoFixHighRoundedIcon />,
      title: intl.formatMessage({ id: 'HOME.CARD.TITLE.RISK' }),
      description: intl.formatMessage({ id: 'HOME.CARD.TEXT.RISK' }),
      image: Risk,
    },
    {
      icon: <SupportAgentRoundedIcon />,
      title: intl.formatMessage({ id: 'HOME.CARD.TITLE.Contingency' }),
      description: intl.formatMessage({ id: 'HOME.CARD.TEXT.Contingency' }),
      image: Contingency,
    },
    {
      icon: <QueryStatsRoundedIcon />,
      title: intl.formatMessage({ id: 'HOME.CARD.TITLE.MEASURING' }),
      description: intl.formatMessage({ id: 'HOME.CARD.TEXT.MEASURING' }),
      image: Equity,
    },
  ]
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 12 },
        // mt: { xs: 4, sm: 12 },
        // mb: { xs: 8, sm: 12 },
        color: 'white',
        // bgcolor: '#1976D2',
        margin: '0 auto',
      }}
      className="home-video-box "
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
        className="home-video-box-container"
      >
        <Box
          sx={{
            width: { sm: '100%', md: '100%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
          className="home-video-box-container-box"
        >
          <video
            autoPlay
            playsInline
            className="home-video-box-container-box-video"
            muted
            controls
          >
            <source src={financialVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  // p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                  textAlign: 'center',
                  overflow: 'hidden', // Ensure the overflow is hidden for the animation
                  position: 'relative', // Position relative for absolute positioning of the animation
                }}
                className="card-container"
              >
                <div className="enlarge-box">
                  <img
                    src={item.image}
                    alt="SVG"
                    style={{ width: '100%', height: 'auto' }}
                    className="image-item"
                  />
                  {/* <Box sx={{ opacity: '50%' }}>{item.icon}</Box> */}
                  <div>
                    <Typography fontWeight="medium" gutterBottom>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'grey.400' }}>
                      {item.description}
                    </Typography>
                  </div>
                </div>
                <div className="animation-overlay"></div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}
