function Spinner() {
  return (
    <div className="loadingSpinnerBackground">
      <div className="loadingSpinnerContainer">
        <div className="loadingSpinner " />
        <div className="header-menu-logo inner">
          <span style={{ color: 'white' }}>Financial</span>
          <span className="header-menu-logo-lion">
            <span >Prediction</span>
          </span>
          <span className="header-menu-logo-lion">
            <span style={{ color: 'white' }}>AI</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Spinner
