import ScrollToTop from 'pages/scrollToTop'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Router from 'Root/Router'

const App = () => {
  return (
    <>
      <Router />
      <ToastContainer />
      <ScrollToTop />
    </>
  )
}

export default App
