import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import {
  analysisCreate,
  createFirm,
  customScenarioCreate,
  customScenarioDataCreate,
  loanRequestCreate,
  equityRequestCreate,
  login,
  realAnalysisCreate,
  realBusinessCreate,
  realFxCreate,
  realMaturityCreate,
  registration,
  financialBusinessCreate,
  financialMaturityCreate,
  financialAnalysisCreate,
} from 'redux/ApiCalls'
import storeConstants from 'redux/constants/actionTypes'
import { toast } from 'react-toastify'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const theme = createTheme()

const Register = (props) => {
  const intl = useIntl()

  const navigate = useNavigate()

  const {
    firmBackground,
    financial_statements,
    fx_position,
    maturity_short_term,
    business_plan,
    analysisResult,
    saved_analysis,
  } = useSelector((state) => state.analysisDataReducer)

  const { addLoan, saveLoan } = useSelector((state) => state.loanRequest)

  const { addEquity, saveEquity } = useSelector((state) => state.equityRequest)

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    company_name: Yup.string().required('Company Name is required'),
    company_web_site: Yup.string().required('Company Web Site is required'),
    city: Yup.string().required('City is required'),
    your_role_at_the_company: Yup.string().required(
      'Your Role At The Company is required'
    ),
    business_description: Yup.string().required(
      'Business Description is required'
    ),
    email: Yup.string().required('Email is required').email('Email is invalid'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
    password2: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
    acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required'),
  })

  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  let custom = analysisResult?.scenarios

  const onSubmit = (data) => {
    registration({ ...data })
      .then(() => {
        login({ email: data.email, password: data.password })
          .then((res) => {
            if (res.data.access) {
              dispatch({
                type: storeConstants.LOGIN_SUCCESS,
                payload: res.data.access,
              })

              toast.success(
                intl.formatMessage({ id: 'TOAST_MESSAGES.REGISTER.SUCCESS' })
              )
              navigate('/myfirm')
              let token = res.data.access
              console.log(saveLoan)
              if (saveLoan) {
                loanRequestCreate(token, addLoan)
                  .then((res) => {
                    toast.success('Loan request created successfully.')
                  })
                  .catch((err) => {
                    toast.error('Loan request creation failed.')
                  })
              }
              if (saveEquity) {
                equityRequestCreate(token, addEquity)
                  .then((res) => {
                    toast.success('Equity request created successfully.')
                  })
                  .catch((err) => {
                    toast.error('Equity request creation failed.')
                  })
              }
              if (saved_analysis) {
                createFirm(res.data.access, {
                  ...firmBackground,
                  foreCastStartDate: firmBackground.foreCastStartDate / 1000,
                  decemberOfLastYear: firmBackground.decemberOfLastYear / 1000,
                  foreCastEndDate: firmBackground.foreCastEndDate / 1000,
                  fiscalYear: firmBackground.fiscalYear / 1000,
                  start_of_operations_year:
                    firmBackground.start_of_operations_year / 1000,
                })
                  .then(({ data }) => {
                    toast.success('Firm Created')
                    let firmSlug = data.slug
                    let token = res.data.access

                    analysisCreate(token, firmSlug, {
                      name: 'SaveAnalysis',
                      is_default: 1,
                    })
                      .then(({ data }) => {
                        toast.success('Analysis Created')
                        let analysisId = data.id
                        customScenarioCreate(token, custom, analysisId).then(
                          ({ data }) => {
                            let customId = data.id
                            let analyzeId = data.analyze
                            custom.map((yearData) => {
                              let newCustom = {}
                              yearData.values.map((data, index) => {
                                newCustom[data.key] = data.value
                              })
                              newCustom.year_for_data = new Date(
                                yearData.year
                              ).toISOString()
                              newCustom.projection_frequency =
                                firmBackground.projection_frequency
                              customScenarioDataCreate(
                                token,
                                newCustom,
                                analyzeId,
                                customId
                              ).catch((err) => {
                                console.log(err)
                              })
                            })
                          }
                        )
                        if (firmBackground.industry === 'Finance & Insurance') {
                          financialAnalysisCreate(
                            token,
                            financial_statements,
                            analysisId
                          )
                          financialFxCreate(token, fx_position, analysisId)
                          financialMaturityCreate(
                            token,
                            maturity_short_term,
                            analysisId
                          )
                          financialBusinessCreate(
                            token,
                            business_plan,
                            analysisId
                          )
                        } else {
                          realAnalysisCreate(
                            token,
                            financial_statements,
                            analysisId
                          )
                          realFxCreate(token, fx_position, analysisId)
                          realMaturityCreate(
                            token,
                            maturity_short_term,
                            analysisId
                          )
                          realBusinessCreate(token, business_plan, analysisId)
                        }
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              } else {
                navigate('/myfirm')
              }
            } else {
              console.log(err)
            }
          })
          .catch((err) => {
            toast.error(
              intl.formatMessage({
                id: 'TOAST_MESSAGES.REGISTER.ERROR',
              })
            )
          })
      })
      .catch((err) => {
        toast.error(
          intl.formatMessage({
            id: 'TOAST_MESSAGES.REGISTER.ERROR',
          })
        )
      })
  }

  const onClick = () => {
    navigate('/login')
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="main-login ">
          <Container component="main" maxWidth="xs">
            <a
              className="button-equlion"
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textDecoration: 'none',
              }}
            >
              <span style={{ color: 'white' }}>Equ</span>
              <span
                className="header-menu-logo-lion"
                style={{ color: '#f73681' }}
              >
                <span style={{ fontSize: '40px' }}>l</span>ion
              </span>
            </a>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, background: 'Transparent' }}></Avatar>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                sx={{ mt: 3 }}
              >
                <svg className="signup-sides">
                  <line
                    className="top-right first"
                    x1="50%"
                    x2="100%"
                    y1="0"
                    y2="0"
                  />
                  <line
                    className="top-left first"
                    x1="50%"
                    x2="0"
                    y1="0"
                    y2="0"
                  />
                  <line
                    className="right second"
                    x1="100%"
                    x2="100%"
                    y1="0%"
                    y2="100%"
                  />
                  <line
                    className="left second"
                    x1="0"
                    x2="0"
                    y1="0"
                    y2="100%"
                  />
                  <line
                    className="bottom-left third"
                    x1="0"
                    x2="50%"
                    y1="100%"
                    y2="100%"
                  />
                  <line
                    className="bottom-right third"
                    x1="100%"
                    x2="50%"
                    y1="100%"
                    y2="100%"
                  />
                </svg>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="first_name"
                      required
                      fullWidth
                      size="small"
                      id="first_name"
                      {...register('first_name')}
                      label={intl.formatMessage({
                        id: 'REGISTER.FIST_NAME',
                      })}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      size="small"
                      id="last_name"
                      {...register('last_name')}
                      label={intl.formatMessage({
                        id: 'REGISTER.LAST_NAME',
                      })}
                      name="last_name"
                      autoComplete="family-name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="email"
                      type="email"
                      required
                      fullWidth
                      size="small"
                      id="email"
                      {...register('email')}
                      label={intl.formatMessage({
                        id: 'REGISTER.EMAIL',
                      })}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      size="small"
                      id="company_web_site"
                      {...register('company_web_site')}
                      label={intl.formatMessage({
                        id: 'REGISTER.COMPANY_WEB_SITE',
                      })}
                      name="company_web_site"
                      autoComplete="family-name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="company_name"
                      required
                      fullWidth
                      size="small"
                      id="company_name"
                      {...register('company_name')}
                      label={intl.formatMessage({
                        id: 'REGISTER.COMPANY_NAME',
                      })}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      size="small"
                      id="your_role_at_the_company"
                      {...register('your_role_at_the_company')}
                      label={intl.formatMessage({
                        id: 'REGISTER.ROLE',
                      })}
                      name="your_role_at_the_company"
                      autoComplete="family-name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="given-name"
                      name="business_description"
                      required
                      fullWidth
                      size="small"
                      id="business_description"
                      {...register('business_description')}
                      label={intl.formatMessage({
                        id: 'REGISTER.BUSINESS_DESCRIPTION',
                      })}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      size="small"
                      id="city"
                      label={intl.formatMessage({
                        id: 'REGISTER.CITY',
                      })}
                      name="city"
                      {...register('city')}
                      autoComplete="family-name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      size="small"
                      name="password"
                      label={intl.formatMessage({
                        id: 'REGISTER.PASSWORD',
                      })}
                      type="password"
                      id="password"
                      {...register('password')}
                      autoComplete="current-password"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      size="small"
                      name="password2"
                      label={intl.formatMessage({
                        id: 'REGISTER.CONFIRM_PASSWORD',
                      })}
                      type="password"
                      id="password2"
                      {...register('password2')}
                      autoComplete="current-password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  size="small"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {intl.formatMessage({
                    id: 'REGISTER.BUTTON',
                  })}
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Button>
                      <Link
                        onClick={onClick}
                        to={
                          saved_analysis ? '/login?saveAnalysis=true' : '/login'
                        }
                        variant="body2"
                      >
                        {intl.formatMessage({
                          id: 'REGISTER.ALREADY_HAVE_ACCOUNT',
                        })}
                      </Link>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </div>
      </ThemeProvider>
    </>
  )
}
export default Register
