import { useSelector } from 'react-redux'
import FinancialAnalysisReport from 'components/FinancialAnalysisReport'
import RealAnalysisReport from 'components/RealAnalysisReport'

const Report = () => {
  const { firmBackground } = useSelector((state) => state.analysisDataReducer)

  return (
    <>
      {firmBackground?.industry === 'Finance & Insurance' ? (
        <FinancialAnalysisReport />
      ) : (
        <RealAnalysisReport />
      )}
    </>
  )
}

export default Report
