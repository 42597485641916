import storeConstants from "redux/constants/actionTypes";

const initialState = {
    loan: [],
    addLoan: [],
    saveLoan: false
}

const loanRequest = (state = initialState, action) => {
    switch (action.type) {
        case storeConstants.GET_LOAN:
            return {
                ...state,
                loan: action.payload,
            }
        case storeConstants.ADD_LOAN:
            return {
                ...state,
                addLoan: action.payload,
            }
        case storeConstants.SAVE_LOAN:
            return {
                ...state,
                saveLoan: action.payload,
            }
        default:
            return state
    }
}

export default loanRequest;