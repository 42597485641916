import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import MyLoader from './MyLoader'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { useState, useEffect } from 'react'
import * as analysisDataActions from '../redux/actions/analysisDataActions'
import BaselineAndCustomChart from './BaselineAndCustomChart'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'

const indicatorsBackgroundColor = [
  'rgba(255, 99, 132, 4)',
  'rgba(54, 162, 235, 4)',
  'rgba(255, 206, 86, 4)',
  'rgba(75, 192, 192, 4)',
  'rgba(153, 102, 255, 4)',
  'rgba(255, 159, 64, 4)',
  'rgba(255, 99, 132, 4)',
]

const ProgressTableAndExcelExport = ({ setExcelRead }) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { rating_valuation, custom_valuation } = useSelector(
    (state) => state.analysisDataReducer.analysisResult
  )
  const {
    firmBackground,
    financial_statements,
    fx_position,
    maturity_short_term,
    business_plan,
    analysisResult,
    initial_custom_scenario,
  } = useSelector((state) => state.analysisDataReducer)

  const scenarios = analysisResult.scenarios

  const [fileName, setFileName] = useState()

  // Financial Statements For Excel
  const balanceSheetTableTh = [
    financial_statements?.map(
      (item, index) =>
        new Date(item.year).toLocaleString('en-US', {
          month: 'short',
        }) +
        ' ' +
        new Date(item.year).toLocaleString('en-US', {
          year: 'numeric',
        })
    ),
  ]

  const incomeTableTh = [
    financial_statements?.map(
      (item, index) =>
        new Date(item.year).toLocaleString('en-US', {
          month: 'short',
        }) +
        ' ' +
        new Date(item.year).toLocaleString('en-US', {
          year: 'numeric',
        })
    ),
  ]

  balanceSheetTableTh[0].unshift('Balance Sheet')
  incomeTableTh[0].unshift('Income Statements')

  const balanceSheetTableTd = financial_statements[0]?.values
    .slice(0, firmBackground.industry === 'Finance & Insurance' ? 14 : 12)
    .map(
      (row, rowIndex) =>
        row.label +
        ', ' +
        financial_statements?.map(
          (yearData, columnIndex) => yearData.values[rowIndex].value
        )
    )

  const labelBalanceSheet = balanceSheetTableTd?.map((k) => {
    return k.split(',').map((el) => {
      let n = Number(el)
      return n === 0 ? n : n || el
    })
  })

  const incomeTableTd = financial_statements[0]?.values
    .slice(
      firmBackground.industry === 'Finance & Insurance' ? 14 : 12,
      financial_statements[0].values.length
    )
    .map(
      (row, rowIndex) =>
        row.label +
        ', ' +
        financial_statements?.map(
          (yearData, columnIndex) =>
            yearData.values[
              rowIndex +
                (firmBackground.industry === 'Finance & Insurance' ? 14 : 12)
            ].value
        )
    )

  const labelIncome = incomeTableTd?.map((k) => {
    return k.split(',').map((el) => {
      let n = Number(el)
      return n === 0 ? n : n || el
    })
  })

  const balanceSheetMerged = balanceSheetTableTh.concat(labelBalanceSheet)
  const incomeMerged = incomeTableTh.concat(labelIncome)

  const exelLayoutFinancial = balanceSheetMerged.concat(incomeMerged)

  //Fx Position For excel

  const fxPositonTableTh = [
    fx_position?.map(
      (item, index) =>
        new Date(item.year).toLocaleString('en-US', {
          year: 'numeric',
        }) +
        '-' +
        new Date(item.year).toLocaleString('en-US', {
          month: 'short',
        })
    ),
  ]

  fxPositonTableTh[0].unshift('Fx Position')

  const fxTableTd = fx_position[0]?.values.map(
    (row, rowIndex) =>
      row.label +
      ', ' +
      fx_position?.map(
        (yearData, columnIndex) => yearData.values[rowIndex].value
      )
  )

  const labelFx = fxTableTd?.map((k) => {
    return k.split(',').map((el) => {
      let n = Number(el)
      return n === 0 ? n : n || el
    })
  })

  const excelLayoutFx = fxPositonTableTh?.concat(labelFx)

  //Maturity Short Term Plan For excel
  const maturityTh = [
    maturity_short_term?.map(
      (item, index) =>
        new Date(item.year).toLocaleString('en-US', {
          year: 'numeric',
        }) +
        '-' +
        new Date(item.year).toLocaleString('en-US', {
          month: 'short',
        })
    ),
  ]
  maturityTh[0].unshift('Maturity Short Term')

  const maturityTd = maturity_short_term[0]?.values.map(
    (row, rowIndex) =>
      row.label +
      ', ' +
      maturity_short_term?.map(
        (yearData, columnIndex) => yearData.values[rowIndex].value
      )
  )
  const labelMaturity = maturityTd?.map((k) => {
    return k.split(',').map((el) => {
      let n = Number(el)
      return n === 0 ? n : n || el
    })
  })

  const excelLayoutMaturity = maturityTh?.concat(labelMaturity)

  //Business Plan For excel
  const businessPlanTh = [
    business_plan?.map(
      (item, index) =>
        new Date(item.year).toLocaleString('en-US', {
          year: 'numeric',
        }) +
        '-' +
        new Date(item.year).toLocaleString('en-US', {
          month: 'short',
        })
    ),
  ]

  businessPlanTh[0].unshift('Business Plan')

  const businessPlanTd = business_plan[0]?.values.map(
    (row, rowIndex) =>
      row.label +
      ', ' +
      business_plan?.map(
        (yearData, columnIndex) => yearData.values[rowIndex].value
      )
  )

  const labelBusinessPlan = businessPlanTd?.map((k) => {
    return k.split(',').map((el) => {
      let n = Number(el)
      return n === 0 ? n : n || el
    })
  })

  const excelLayoutBusinessPlan = businessPlanTh?.concat(labelBusinessPlan)

  //Custom Scenario

  const customScenariTh = analysisResult.scenarios && [
    scenarios?.map(
      (item, index) =>
        new Date(item.year).toLocaleString('en-US', {
          year: 'numeric',
        }) +
        '-' +
        new Date(item.year).toLocaleString('en-US', {
          month: 'short',
        })
    ),
  ]

  analysisResult.scenarios && customScenariTh[0].unshift('Custom Scenario')

  const customScenariTd =
    analysisResult.scenarios &&
    scenarios[0]?.values.map(
      (row, rowIndex) =>
        row.label +
        ', ' +
        scenarios?.map(
          (yearData, columnIndex) => yearData.values[rowIndex].value
        )
    )

  const labelCustomScenario =
    analysisResult.scenarios &&
    customScenariTd?.map((k) => {
      return k.split(',').map((el) => {
        let n = Number(el)
        return n === 0 ? n : n || el
      })
    })

  const excelLayoutCustomScenario =
    analysisResult.scenarios && customScenariTh?.concat(labelCustomScenario)


    console.log("exelLayoutFinancial:",exelLayoutFinancial)
    const modifiedExelLayoutFinancial = exelLayoutFinancial.map(subArray =>
      subArray.map(item =>
        typeof item === 'string' ? item.replace(/\(S\)|\(F\)/g, '') : item
      )
    );
    
    console.log("modifiedExelLayoutFinancial:",modifiedExelLayoutFinancial)

  function exportToCSV() {
    var wb = XLSX.utils.book_new()

    var wsFinancial = XLSX.utils.json_to_sheet(modifiedExelLayoutFinancial)

    var range = XLSX.utils.decode_range(wsFinancial['!ref'])
    range.s.r = 1 // <-- zero-indexed, so setting to 1 will skip row 0
    wsFinancial['!ref'] = XLSX.utils.encode_range(range)
    XLSX.utils.sheet_to_json(wsFinancial, {
      defval: '',
    })
    XLSX.utils.book_append_sheet(wb, wsFinancial, 'Financial Statements')

    var wsFx = XLSX.utils.json_to_sheet(excelLayoutFx)

    var range = XLSX.utils.decode_range(wsFx['!ref'])
    range.s.r = 1 // <-- zero-indexed, so setting to 1 will skip row 0s
    wsFx['!ref'] = XLSX.utils.encode_range(range)
    XLSX.utils.sheet_to_json(wsFx, {
      defval: '',
    })

    XLSX.utils.book_append_sheet(wb, wsFx, 'Fx Position')

    var wsMaturity = XLSX.utils.json_to_sheet(excelLayoutMaturity)

    var range = XLSX.utils.decode_range(wsMaturity['!ref'])
    range.s.r = 1 // <-- zero-indexed, so setting to 1 will skip row 0
    wsMaturity['!ref'] = XLSX.utils.encode_range(range)
    XLSX.utils.sheet_to_json(wsMaturity, {
      defval: '',
    })

    XLSX.utils.book_append_sheet(wb, wsMaturity, 'Maturity Short Term')

    var wsBusinessPlan = XLSX.utils.json_to_sheet(excelLayoutBusinessPlan)

    var range = XLSX.utils.decode_range(wsBusinessPlan['!ref'])
    range.s.r = 1 // <-- zero-indexed, so setting to 1 will skip row 0
    wsBusinessPlan['!ref'] = XLSX.utils.encode_range(range)
    XLSX.utils.sheet_to_json(wsBusinessPlan, {
      defval: '',
    })

    XLSX.utils.book_append_sheet(wb, wsBusinessPlan, 'Business Plan')

    var wsCustomScenario = XLSX.utils.json_to_sheet(excelLayoutCustomScenario)

    var range = XLSX.utils.decode_range(wsCustomScenario['!ref'])
    range.s.r = 1 // <-- zero-indexed, so setting to 1 will skip row 0
    wsCustomScenario['!ref'] = XLSX.utils.encode_range(range)
    XLSX.utils.sheet_to_json(wsCustomScenario, {
      defval: '',
    })

    XLSX.utils.book_append_sheet(wb, wsCustomScenario, 'Custom Scenario')

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, 'Analysis.xlsx')
  }

  const handleFile = async (e) => {
    const file = e.target.files[0]

    setFileName(file)

    const data = await file.arrayBuffer()
    const workbook = XLSX.readFile(data)

    const worksheetFinancial = workbook.Sheets[workbook.SheetNames[0]]
    const worksheetFx = workbook.Sheets[workbook.SheetNames[1]]
    const worksheetMaturity = workbook.Sheets[workbook.SheetNames[2]]
    const worksheetBusiness = workbook.Sheets[workbook.SheetNames[3]]
    const worksheetCustom = workbook.Sheets[workbook.SheetNames[4]]

    const jsonDataFinancial = XLSX.utils.sheet_to_json(worksheetFinancial, {
      header: 1,
      defval: '',
    })
    const jsonDataFx = XLSX.utils.sheet_to_json(worksheetFx, {
      header: 1,
      defval: '',
    })
    const jsonDataMaturity = XLSX.utils.sheet_to_json(worksheetMaturity, {
      header: 1,
      defval: '',
    })
    const jsonDataBusiness = XLSX.utils.sheet_to_json(worksheetBusiness, {
      header: 1,
      defval: '',
    })
    const jsonDataCustom = XLSX.utils.sheet_to_json(worksheetCustom, {
      header: 1,
      defval: '',
    })

    setExcelRead({
      financial_statements: [jsonDataFinancial],
      fx_position: [jsonDataFx],
      maturity_short_term: [jsonDataMaturity],
      business_plan: [jsonDataBusiness],
      custom_scenarios: [jsonDataCustom],
    })

    const formatingMapFinancial = jsonDataFinancial.reduce((acc, row) => {
      acc[row.shift()] = row.filter((d) => d !== '')
      return acc
    }, {})
    const formatingMapFx = jsonDataFx.reduce((acc, row) => {
      acc[row.shift()] = row.filter((d) => d !== '')
      return acc
    }, {})
    const formatingMapMaturity = jsonDataMaturity.reduce((acc, row) => {
      acc[row.shift()] = row.filter((d) => d !== '')
      return acc
    }, {})
    const formatingMapBusiness = jsonDataBusiness.reduce((acc, row) => {
      acc[row.shift()] = row.filter((d) => d !== '')
      return acc
    }, {})
    const formatingMapCustom = jsonDataCustom.reduce((acc, row) => {
      acc[row.shift()] = row.filter((d) => d !== '')
      return acc
    }, {})

    delete formatingMapFinancial['Income Statements']

    const transformedFinancialData = formatingMapFinancial['Balance Sheet'].map(
      (year, index) => ({
        year: year,
        values: Object.entries(formatingMapFinancial)
          .slice(1)
          .map(([label, values]) => ({
            key: label
              .replaceAll(/ /g, '_')
              .toLowerCase()
              .replace(
                'cost_of_sales_of_goods_and_services_sold',
                'cost_of_goods_and_services_sold'
              ),
            label: label,
            value: (Array.isArray(values) ? values[index] : values).toString(),
          })),
      })
    )

    const transformedFxData = formatingMapFx['Fx Position'].map(
      (year, index) => ({
        year: year,
        values: Object.entries(formatingMapFx)
          .slice(1)
          .map(([label, values]) => ({
            key: label
              .replaceAll(/ /g, '_')
              .toLowerCase()
              .replace(
                'cost_of_sales_of_goods_and_services_sold',
                'cost_of_goods_and_services_sold'
              ),
            label: label,
            value: (Array.isArray(values) ? values[index] : values).toString(),
          })),
      })
    )

    const transformedMaturityData = formatingMapMaturity[
      'Maturity Short Term'
    ].map((year, index) => ({
      year: year,
      values: Object.entries(formatingMapMaturity)
        .slice(1)
        .map(([label, values]) => ({
          key: label
            .replaceAll(/ /g, '_')
            .toLowerCase()
            .replace(
              'cost_of_sales_of_goods_and_services_sold',
              'cost_of_goods_and_services_sold'
            ),
          label: label,
          value: (Array.isArray(values) ? values[index] : values).toString(),
        })),
    }))

    const transformedBusinessData = formatingMapBusiness['Business Plan'].map(
      (year, index) => ({
        year: year,
        values: Object.entries(formatingMapBusiness)
          .slice(1)
          .map(([label, values]) => ({
            key: label
              .replaceAll(/ /g, '_')
              .toLowerCase()
              .replace(
                'gross_margin_(%)',
                'gross_margin' || 'growth_rate_of_sales_(%)',
                'growth_rate_of_sales' || 'interest_rate_on_borrowings_(%)',
                'increase_in_borrowings' || 'return_on_equity_investments_(%)',
                'increase_in_equity_investment'
              ),
            label: label,
            value: (Array.isArray(values) ? values[index] : values).toString(),
          })),
      })
    )

    const transformedCustomyData = formatingMapCustom['Custom Scenario'].map(
      (year, index) => ({
        year: year,
        values: Object.entries(formatingMapCustom)
          .slice(1)
          .map(([label, values]) => ({
            key: label
              .replaceAll('Export Market Inflation', 'US_INF')
              .replaceAll('Export Market GDP', 'WGDP')
              .replaceAll('Domestic Market GDP', 'GDP')
              .replaceAll('FX Rate (LCY/USD)', 'FX')
              .replaceAll('Lending Rate on Local Curency Debts', 'LR')
              .replaceAll('Inflation', 'INF')
              .replaceAll('Lending Rate on Foreign Curency Debts', 'US_LR')
              .replaceAll('Rate of Return on Domestic Equity Investments', 'ER')
              .replaceAll(
                'Rate of Return on Foreign Equity Investments',
                'US_ER'
              ),
            label: label,
            value: (Array.isArray(values) ? values[index] : values).toString(),
          })),
      })
    )

    dispatch(
      analysisDataActions.excelImport({
        financial_statements: transformedFinancialData,
        fx_position: transformedFxData,
        business_plan: transformedBusinessData,
        maturity_short_term: transformedMaturityData,
        // scenarios:transformedCustomyData
        // analysisResult:{
        //   custom_scenario:transformedCustomyData
        // }
      })
    )

    dispatch(
      analysisDataActions.excelImportForCustom({
        scenarios: transformedCustomyData,
      })
    )

    function compareValues(initialValue, customValue) {
      return initialValue !== customValue
    }

    function compareData(initial, custom) {
      const differences = []

      for (let i = 0; i < initial.length; i++) {
        const initialEntry = initial[i]
        const customEntry = custom[i]

        for (let j = 0; j < initialEntry.values.length; j++) {
          const initialValue = Number(initialEntry.values[j].value)
          const customValue = Number(customEntry.values[j].value)

          if (compareValues(initialValue, Number(customValue))) {
            differences.push({
              year: initialEntry.year,
              key: initialEntry.values[j].key,
              label: initialEntry.values[j].label,
              initialValue,
              customValue,
            })
          }
        }
      }
      return differences
    }

    const differences = compareData(scenarios, transformedCustomyData)

    if (differences.length === 0) {
      console.log('No differences found.')
      dispatch(analysisDataActions.disableCustomScenario())
    }
    if (differences.length !== 0) {
      console.log('Differences found:')
      dispatch(analysisDataActions.enableCustomScenario())
    }
  }

  const RaitingDataBar =
    rating_valuation && custom_valuation[0]?.value === 'N/A'
      ? {
          labels: ['Baseline'],
          datasets: [
            {
              label: 'Raiting',
              backgroundColor: indicatorsBackgroundColor,
              borderColor: 'rgba(255,99,132,1)',
              barThickness: 15,
              data: [rating_valuation[0]?.value?.toFixed(3)],
            },
          ],
        }
      : rating_valuation &&
        custom_valuation[0]?.value !== 'N/A' && {
          labels: ['Baseline', 'Custom'],
          datasets: [
            {
              label: 'Raiting',
              backgroundColor: indicatorsBackgroundColor,
              borderColor: 'rgba(255,99,132,1)',
              barThickness: 25,
              data: [
                rating_valuation[0]?.value?.toFixed(3),
                custom_valuation[0]?.value,
              ],
            },
          ],
        }

  const ValuationDataBar =
    rating_valuation && custom_valuation[0]?.value === 'N/A'
      ? {
          labels: ['Baseline'],
          datasets: [
            {
              label: 'Valuation',
              backgroundColor: indicatorsBackgroundColor,
              borderColor: 'rgba(255,99,132,1)',
              barThickness: 15,
              data: [rating_valuation[1]?.value?.toFixed(3)],
            },
          ],
        }
      : rating_valuation &&
        custom_valuation[1]?.value !== 'N/A' && {
          labels: ['Baseline', 'Custom'],
          datasets: [
            {
              label: 'Valuation',
              backgroundColor: indicatorsBackgroundColor,
              borderColor: 'rgba(255,99,132,1)',
              barThickness: 25,
              data: [
                rating_valuation[1]?.value?.toFixed(3),
                custom_valuation[1]?.value,
              ],
            },
          ],
        }

  const options = {
    plugins: {
      datalabels: {
        display: true,
        color: 'black',
        formatter: function (value, context) {
          return Number(value)?.toFixed(2)
        },
        anchor: 'end',
        offset: -20,
        align: 'start',
      },
      legend: {
        display: false,
      },
    },
  }

  useEffect(() => {
    Chart.register(ChartDataLabels)
  }, [])

  const handleFlowChange = () => {
    dispatch(
      analysisDataActions.firmBackgroundSelect({
        ...firmBackground,
        flowOrstock: true,
      })
    )
  }

  const handleStockChange = () => {
    dispatch(
      analysisDataActions.firmBackgroundSelect({
        ...firmBackground,
        flowOrstock: false,
      })
    )
  }

  return (
    <>
      {rating_valuation ? (
        <div className="main-progress">
          <div className="main-progress-container">
            <div
              className="main-progress-container-content baseline_content"
              style={{
                flexDirection: 'column',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              {intl.formatMessage({
                id: 'PROGRESS_TABLE.FIRM_RATING',
              })}
              {rating_valuation ? (
                <div style={{ width: '60%' }}>
                  <Bar
                    data={RaitingDataBar}
                    options={options}
                    width={100}
                    height={50}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
            <div
              className="main-progress-container-content-excel_content"
            >
              <button
                className="btn btn-primary analysis_button main-progress-container-content-excel_content-button"
                onClick={(e) => exportToCSV()}
                style={{ minWidth: '200px' }}
              >
                {intl.formatMessage({
                  id: 'DOWNLOAD_EXCEL_FILE',
                })}
              </button>
              <div>
                <label
                  className="btn btn-primary analysis_button  main-progress-container-content-excel_content-button"
                  style={{ minWidth: '200px' }}
                >
                  <span style={{ display: 'inline-block' }}>
                    {intl.formatMessage({
                      id: 'UPLOAD_EXCEL_FILE',
                    })}
                  </span>
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => handleFile(e)}
                  />
                </label>
              </div>
              <div>
                {fileName && (
                  <p>
                    <i>
                      {intl.formatMessage({
                        id: 'FILE_NAME',
                      })}{' '}
                      : <span>{fileName.name}</span>
                    </i>
                  </p>
                )}
              </div>
            </div>
            <div
              className="main-progress-container-content custom_content"
              style={{
                flexDirection: 'column',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              {intl.formatMessage({
                id: 'PROGRESS_TABLE.FIRM_VALUDATION',
              })}

              {rating_valuation ? (
                <div style={{ width: '60%' }}>
                  <Bar
                    data={ValuationDataBar}
                    options={options}
                    width={100}
                    height={50}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
           
          </div>
        </div>
      ) : (
        <div className="container"  style={{ paddingLeft: 0, paddingRight: 0}}>
          <>
            <div className="main-progress-container" >
              <div className="main-progress-container-content baseline_content">
                <table className="main-progress-container-content-table">
                  <thead className="main-progress-container-content-table-header">
                    <tr>
                      <th scope="col" style={{ fontWeight: 'bold' }}>
                        {intl.formatMessage({
                          id: 'PROGRESS_TABLE.FIRM_RATING',
                        })}
                      </th>
                    </tr>
                  </thead>
                  <hr />
                  <tbody className="main-progress-container-content-table-body">
                    <MyLoader />
                  </tbody>
                </table>
              </div>
              <div className="main-progress-container-content">
                <table className="main-progress-container-content-table">
                  <div>
                    <MyLoader />
                  </div>
                  <div className="main-progress-container-content-table-body">
                    <MyLoader />
                  </div>
                </table>
              </div>
              <div className="main-progress-container-content custom_content">
                <table className="main-progress-container-content-table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="main-progress-container-content-table-header"
                        style={{ fontWeight: 'bold' }}
                      >
                        {intl.formatMessage({
                          id: 'PROGRESS_TABLE.FIRM_VALUDATION',
                        })}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="main-progress-container-content-table-body">
                    <MyLoader />
                  </tbody>
                </table>
              </div>
         
            
            </div>
            <div className="analysis-table-header-container">
              <div className="analysis-table-header-container-sub">
                <div className="analysis-table-header-container-sub-text">
                  <MyLoader />
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  )
}

export default ProgressTableAndExcelExport
