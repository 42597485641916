import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import styles from 'assets/styles/pages/myfirm.module.scss'

// redux
import { edit, getFirmList, getMyFirms } from 'redux/ApiCalls'

// components
import { Container, Section } from 'components/layout'
import Buttons from 'components/button'

// config


import { Link } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import storeConstants from 'redux/constants/actionTypes'
import ReactTooltip from 'react-tooltip'
import { Button } from '@mui/material'


const theme = createTheme();

const MyFirmUpdate = () => {
  const intl = useIntl()
  const { token } = useSelector((state) => state.auth)
  const myFirm = useSelector((state) => state.myfirm.myfirm)
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    token,
    first_name: '',
    last_name: '',
    email: "",
    company_name: '',
    company_web_site: '',
    your_role_at_the_company: '',
    business_description: '',
    city: '',
  })


  const refreshFormData = () => {
    if (myFirm.profile) {
      setFormData({
        token,
        first_name: myFirm?.first_name,
        last_name: myFirm?.last_name,
        email: myFirm?.email,
        company_name: myFirm?.profile.company_name,
        company_web_site: myFirm?.profile.company_web_site,
        your_role_at_the_company: myFirm?.profile.your_role_at_the_company,
        business_description: myFirm?.profile.business_description,
        city: myFirm?.profile.city,
      })
    }
  }

  useEffect(() => {
    if (myFirm) {
      refreshFormData()
    }
  }, [])


  const updateFormData = event => setFormData({
    ...formData,
    [event.target.name]: event.target.value
  });

  const getMyFirm = () => {
    getMyFirms(token)
      .then((res) => {
        dispatch({
          type: storeConstants.GET_MY_SHOW_FIRM,
          payload: res.data,
        })
      })
      .catch(console.log)
  }

  const onSubmit = (e) => {
    e.preventDefault();
    edit(formData).then(res => {
      getMyFirm()
    }).catch(err => {
      console.log(err)
    })
  }



  useEffect(() => {
    getMyFirm()
  }, [])

  useEffect(() => {
    getFirmList(token)
      .then((res) => {
        dispatch({
          type: storeConstants.GET_FIRM_LIST,
          payload: res.data.results,
        })
      })
      .catch((e) => console.log(e))
  }, [])



  return (
    <Container>

      <div className={styles.page}>
        <div className={`${styles.page_content} ${styles.row}`}>
          <ThemeProvider theme={theme}>
            <div className='container'>
              <Box
                sx={{
                  marginTop: 3,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: "space-between"
                }}
              >
                <div style={{ marginTop: "80px" }}>
                </div>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 3, ml: 3, border: "2px solid var(--colors-light-200)", padding: "1rem", borderRadius: "5px", textAlign: "center" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} className="sd">
                      <TextField
                        autoComplete="given-name"
                        name="first_name"
                        required
                        fullWidth
                        size="small"
                        defaultValue={myFirm?.first_name}
                        onChange={e => updateFormData(e)}
                        id="first_name"
                        label={
                          intl.formatMessage({
                            id: 'REGISTER.FIST_NAME',
                          })
                        }
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        size="small"
                        defaultValue={myFirm.last_name}
                        onChange={e => updateFormData(e)}
                        id="last_name"
                        label={intl.formatMessage({
                          id: 'REGISTER.LAST_NAME',
                        })}
                        name="last_name"
                        autoComplete="family-name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="given-name"
                        name="email"
                        type='email'
                        required
                        fullWidth
                        size="small"
                        defaultValue={myFirm.email}
                        onChange={e => updateFormData(e)}
                        id="email"
                        label={intl.formatMessage({
                          id: 'REGISTER.EMAIL',
                        })}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        size="small"
                        defaultValue={myFirm.profile.company_web_site}
                        onChange={e => updateFormData(e)}
                        id="company_web_site"
                        label={
                          intl.formatMessage({
                            id: 'REGISTER.COMPANY_WEB_SITE',
                          })
                        }
                        name="company_web_site"
                        autoComplete="family-name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="given-name"
                        name="company_name"
                        required
                        fullWidth
                        size="small"
                        defaultValue={myFirm.profile.company_name}
                        onChange={e => updateFormData(e)}
                        id="company_name"
                        label={
                          intl.formatMessage({
                            id: 'REGISTER.COMPANY_NAME',
                          })
                        }
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        size="small"
                        defaultValue={myFirm.profile.your_role_at_the_company}
                        onChange={e => updateFormData(e)}
                        id="your_role_at_the_company"
                        label={intl.formatMessage({
                          id: 'REGISTER.ROLE',
                        })}
                        name="your_role_at_the_company"
                        autoComplete="family-name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="given-name"
                        name="business_description"
                        required
                        fullWidth
                        size="small"
                        defaultValue={myFirm.profile.business_description}
                        onChange={e => updateFormData(e)}
                        id="business_description"
                        label={intl.formatMessage({
                          id: 'REGISTER.BUSINESS_DESCRIPTION',
                        })}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        size="small"
                        defaultValue={myFirm.profile.city}
                        onChange={e => updateFormData(e)}
                        id="city"
                        label={intl.formatMessage({
                          id: 'REGISTER.CITY',
                        })}
                        name="city"
                        autoComplete="family-name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        size="small"
                        defaultValue={formData.password}
                        onChange={e => updateFormData(e)}
                        name="password"
                        label={intl.formatMessage({
                          id: 'REGISTER.PASSWORD',
                        })}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        size="small"
                        defaultValue={formData.password2}
                        onChange={e => updateFormData(e)}
                        name="password2"
                        label={intl.formatMessage({
                          id: 'REGISTER.CONFIRM_PASSWORD',
                        })}
                        type="password"
                        id="password2"
                        autoComplete="current-password"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {intl.formatMessage({
                      id: 'SIDEBAR_ROUTES.UPDATE_FIRM',
                    })}
                  </Button>
                  <Grid container justifyContent="flex-end">

                  </Grid>
                </Box>
              </Box>
            </div>
          </ThemeProvider>
        </div>
      </div>
    </Container >
  )
}

export default MyFirmUpdate