import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import styles from 'assets/styles/components/reportFirmbackground/report-firmbackground.module.scss'
import stylesR from 'assets/styles/pages/report.module.scss'

const ReportFirmbackground = () => {
  const intl = useIntl()
  const { firmBackground } = useSelector((state) => state.analysisDataReducer)

  const baseYearDate = new Date(firmBackground?.foreCastStartDate)
  const baseYear = baseYearDate.getFullYear()
  const baseMonth = baseYearDate.getMonth() + 1
  const baseFormattedDate = `${baseYear}-${baseMonth
    .toString()
    .padStart(2, '0')}`

  const endYearDate = new Date(firmBackground?.foreCastEndDate)
  const endYear = endYearDate.getFullYear()
  const endMonth = endYearDate.getMonth() + 1
  const endFormattedDate = `${endYear}-${endMonth.toString().padStart(2, '0')}`

  return (
    <>
      <div
        className={`${
          stylesR.report_container_main_header_common
        } ${'col-12 text-left mb-3'}`}
      >
        I. Firm Background
      </div>
      {(() => (
        <div className={styles.report_firm_background_container}>
          <div
            style={{ fontSize: '18px', paddingLeft: 10, fontWeight: 'bold' }}
            className={`${styles.title} ${'mt-1'}`}
          >
            {intl.formatMessage({
              id: 'FIRMBACKGROUND.FIRM_NAME',
            })}
            <span style={{ position: 'relative', zIndex: '0' }}>
              {firmBackground?.name}
            </span>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <li className={`${styles.title} ${'mt-1'}`}>
                <span style={{ position: 'relative', zIndex: '0' }}></span>
              </li>
              <li className={`${styles.title} ${'mt-1'}`}>
                {intl.formatMessage({
                  id: 'FIRMBACKGROUND.COUNTRY',
                })}
                <span style={{ position: 'relative', zIndex: '0' }}>
                  {firmBackground?.country}
                </span>
              </li>
              <li className={`${styles.title} ${'mt-1'}`}>
                {intl.formatMessage({
                  id: 'FIRMBACKGROUND.INDUSTTRY',
                })}
                <span style={{ position: 'relative', zIndex: '0' }}>
                  {firmBackground?.industry}
                </span>
              </li>
            </div>

            <div className={styles.col_2}>
              <li className={`${styles.title} ${'mt-1'}`}>
                {intl.formatMessage({
                  id: 'FIRMBACKGROUND.BASEYEAR',
                })}
                <span style={{ position: 'relative', zIndex: '0' }}>
                  {baseFormattedDate}
                </span>
              </li>
              <li className={`${styles.title} ${'mt-1'}`}>
                {intl.formatMessage({
                  id: 'FIRMBACKGROUND.FREQUENCY',
                })}
                <span style={{ position: 'relative', zIndex: '0' }}>
                  {firmBackground?.projection_frequency}
                </span>
              </li>
              <li className={`${styles.title} ${'mt-1'}`}>
                {intl.formatMessage({
                  id: 'FIRMBACKGROUND.ENDYEAR',
                })}
                <span style={{ position: 'relative', zIndex: '0' }}>
                  {endFormattedDate}
                </span>
              </li>
              <li className={`${styles.title} ${'mt-1'}`}>
                {intl.formatMessage({
                  id: 'FIRMBACKGROUND.DECIMAL',
                })}
                <span style={{ position: 'relative', zIndex: '0' }}>
                  {firmBackground?.currency_sym}
                </span>
              </li>
            </div>
          </div>
        </div>
      ))()}
    </>
  )
}

export default ReportFirmbackground
