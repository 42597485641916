import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'

function AnimalSections() {
  const intl = useIntl()

  useEffect(() => {
    const targets = document.querySelectorAll('.section')
    const threshold = 0.5

    const setAnim = (entries, observer) => {
      entries.forEach((entry) => {
        const elem = entry.target
        if (entry.isIntersecting) {
          elem.classList.add('anim')
        } else {
          elem.classList.remove('anim')
        }
      })
    }

    const observer = new IntersectionObserver(setAnim, { threshold })
    for (const target of targets) {
      observer.observe(target)
    }

    // Cleanup functionc
    return () => {
      observer.disconnect()
    }
  }, [])
  return (
    <div className="main container">
      <section className="section">
        <div className="section-content">
          <h2 className="section-title">
            {intl.formatMessage({ id: 'HOME.CONTENT.STEP.ONE.TITLE' })}
          </h2>
          <p className="section-text">
            {intl.formatMessage({ id: 'HOME.CONTENT.STEP.ONE.TEXT' })}
          </p>
        </div>
        <div className="section-image">
          <div className="image"></div>
        </div>
      </section>

      <section className="section">
        <div className="section-content">
          <h2 className="section-title">
            {intl.formatMessage({ id: 'HOME.CONTENT.STEP.TWO.TITLE' })}
          </h2>
          <p className="section-text">
            {intl.formatMessage({ id: 'HOME.CONTENT.STEP.TWO.TEXT' })}
          </p>
        </div>
        <div className="section-image">
          <div className="image"></div>
        </div>
      </section>

      <section className="section">
        <div className="section-content">
          <h2 className="section-title">
            {intl.formatMessage({ id: 'HOME.CONTENT.STEP.THERE.TITLE' })}
          </h2>
          <p className="section-text">
            {intl.formatMessage({ id: 'HOME.CONTENT.STEP.THERE.TEXT' })}
          </p>
        </div>
        <div className="section-image">
          <div className="image"></div>
        </div>
      </section>

      <section className="section">
        <div className="section-content">
          <h2 className="section-title">
            {intl.formatMessage({ id: 'HOME.CONTENT.STEP.FOR.TITLE' })}
          </h2>
          <p className="section-text">
            {intl.formatMessage({ id: 'HOME.CONTENT.STEP.FOR.TEXT' })}
          </p>
        </div>
        <div className="section-image">
          <div className="image"></div>
        </div>
      </section>

      <section className="section">
        <div className="section-content">
          <h2 className="section-title">
            {intl.formatMessage({ id: 'HOME.CONTENT.STEP.FIVE.TITLE' })}
          </h2>
          <p className="section-text">
            {intl.formatMessage({ id: 'HOME.CONTENT.STEP.FIVE.TEXT' })}
          </p>
        </div>
        <div className="section-image">
          <div className="image"></div>
        </div>
      </section>

      <section className="section">
        <div className="section-content">
          <h2 className="section-title">
            {intl.formatMessage({ id: 'HOME.CONTENT.STEP.SIX.TITLE' })}
          </h2>
          <p className="section-text">
            {intl.formatMessage({ id: 'HOME.CONTENT.STEP.SIX.TEXT' })}
          </p>
        </div>
        <div className="section-image">
          <div className="image"></div>
        </div>
      </section>
    </div>
  )
}

export default AnimalSections
