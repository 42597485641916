import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded'
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded'
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded'

const items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: 'Tahminlerin kullanımına göre bir zaman dilimi ve sıklık seçin',
    description:
      'Tahminlerin kullanımına göre bir zaman dilimi ve sıklık seçin: Aylıktan yıllığa kadar herhangi bir sıklıkta kısa vadeli veya uzun vadeli olabilir',
    imageLight: 'url("../assets/images/business.png")',
    imageDark: 'url("../assets/images/business.png")',
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: 'Geçmiş ve güncel finansal verileri girin',
    description: 'Basitleştirilmiş bir formatta bilanço ve gelir tablosu',
    imageLight: 'url("../assets/images/budget.pn")',
    imageDark: 'url("../assets/images/budget.pn")',
  },
  {
    icon: <DevicesRoundedIcon />,
    title: 'Mevcutsa finansal veriler hakkında ayrıntı sağlayın',
    description: 'Mevcut bilançonun döviz ve vade yapısı',
    imageLight: 'url("../assets/images/cash.png")',
    imageDark: 'url("../assets/images/cash.png")',
  },
  // {
  //   icon: <DevicesRoundedIcon />,
  //   title: 'Kurumsal iş planlaması',
  //   description:
  //     'İş büyümesi, sermayeleştirme, gelecek için maliyet verimliliği',
  //   imageLight: 'url("../assets/images/risk.png")',
  //   imageDark: 'url("../assets/images/risk.png")',
  // },
  // {
  //   icon: <DevicesRoundedIcon />,
  //   title: 'Gerekirse ülke için makro-finansal göstergeleri özelleştirin',
  //   description:
  //     'FinancialPredictionAI, temel olarak şirketin faaliyet gösterdiği ülke için makroekonomik ve finansal sektör göstergelerini tahmin edecektir.Ancak, kullanıcı makroekonomik ve finansal piyasa tahminlerini özelleştirme seçeneğine sahiptir.Bu durumda FinancialPredictionAI hem temel hem de özelleştirilmiş makroekonomik ve finansal göstergelerin sonuçlarını üretecektir.',
  //   imageLight: 'url("../assets/images/contingency.png")',
  //   imageDark: 'url("../assets/images/contingency.png")',
  // },
  // {
  //   icon: <DevicesRoundedIcon />,
  //   title: 'Tahmin raporunu gözden geçirin ve tahmini iyileştirin',
  //   description:
  //     'Raporu inceledikten sonra, kullanıcı daha doğru ve gerçekçi bir tahmin için iç ve dış girdilerin yanı sıra diğer varsayımları da değiştirebilir.',
  //   imageLight: 'url("../assets/images/equity.png")',
  //   imageDark: 'url("../assets/images/equity.png")',
  // },
]

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0)

  const handleItemClick = (index) => {
    setSelectedItemIndex(index)
  }

  const selectedFeature = items[selectedItemIndex]

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
              {/* Business planning */}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              Kullanıcı 6 adımda bir finansal tahmin gerçekleştirebilir
            </Typography>
          </div>
          <Grid
            container
            item
            gap={1}
            sx={{ display: { xs: 'auto', sm: 'none' } }}
          >
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'primary.light' : ''
                    }
                    return selectedItemIndex === index ? 'primary.light' : ''
                  },
                  background: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'none' : ''
                    }
                    return selectedItemIndex === index ? 'none' : ''
                  },
                  backgroundColor:
                    selectedItemIndex === index ? 'primary.main' : '',
                  '& .MuiChip-label': {
                    color: selectedItemIndex === index ? '#fff' : '',
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            <Box
              sx={{
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: 280,
              }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography
                color="text.primary"
                variant="body2"
                fontWeight="bold"
              >
                {selectedFeature.title}
              </Typography>
              <Typography
                color="text.secondary"
                variant="body2"
                sx={{ my: 0.5 }}
              >
                {selectedFeature.description}
              </Typography>
              <Link
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}
              >
                <span>Learn more</span>
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: '1px', ml: '2px' }}
                />
              </Link>
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  backgroundColor:
                    selectedItemIndex === index ? 'action.selected' : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index
                        ? 'primary.light'
                        : 'grey.200'
                    }
                    return selectedItemIndex === index
                      ? 'primary.dark'
                      : 'grey.800'
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return selectedItemIndex === index
                            ? 'primary.main'
                            : 'grey.300'
                        }
                        return selectedItemIndex === index
                          ? 'primary.main'
                          : 'grey.700'
                      },
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ textTransform: 'none' }}>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                    <Link
                      color="primary"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& > svg': { transition: '0.2s' },
                        '&:hover > svg': { transform: 'translateX(2px)' },
                      }}
                      onClick={(event) => {
                        event.stopPropagation()
                      }}
                    >
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon
                        fontSize="small"
                        sx={{ mt: '1px', ml: '2px' }}
                      />
                    </Link>
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        >
          <Card
            variant="outlined"
            sx={{
              height: '400px',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
              position: 'sticky',
            }}
          >
            <Box
              sx={{
                m: 'auto',
                width: 420,
                height: 500,
                backgroundSize: 'contain',
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
