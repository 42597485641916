import axios from 'axios'
console.log(process.env.REACT_APP_REST_URL,"process.env.REACT_APP_REST_URL")
const DEFAULT_ANALYSİS_TABLES_BY_YEAR_AND_INDUSTRY = `${process.env.REACT_APP_REST_URL}/analyze/table/default/`
const DEFAULT_ANALYSİS_TABLES_BY_YEAR_AND_INDUSTRY_EXCEL = `${process.env.REACT_APP_REST_URL}/analyze/table/default_excel/`
const ANALYSIS = `${process.env.REACT_APP_REST_URL}/analyze/start/`
const FIRM_BACKGROUND = `${process.env.REACT_APP_REST_URL}/firm/`
const FIRM_LIST = `${process.env.REACT_APP_REST_URL}/firm/list/`
const FIRM_CREATE = `${process.env.REACT_APP_REST_URL}/firm/create/`
const REGISTER = `${process.env.REACT_APP_REST_URL}/user/register/`
const USER_UPDATE = `${process.env.REACT_APP_REST_URL}/user/update/`
const LOGIN = `${process.env.REACT_APP_REST_URL}/user/token/`
const EDIT = `${process.env.REACT_APP_REST_URL}/user/update/`
const MY_FIRMS = `${process.env.REACT_APP_REST_URL}/user/detail/`
const LOGOUT = `${process.env.REACT_APP_REST_URL}/user/token/refresh/`
const RESET_PASSWORD = `${process.env.REACT_APP_REST_URL}/user/request-reset-email/`

const registration = ({
  business_description,
  city,
  email,
  company_web_site,
  your_role_at_the_company,
  first_name,
  last_name,
  company_name,
  password,
  password2,
}) => {
  const header = {
    'Content-Type': 'application/json',
  }

  return axios.post(
    REGISTER,
    {
      business_description,
      city,
      email,
      company_web_site,
      your_role_at_the_company,
      first_name,
      last_name,
      company_name,
      password,
      password2,
    },
    {
      headers: header,
    }
  )
}

const edit = ({
  first_name,
  last_name,
  email,
  company_name,
  company_web_site,
  your_role_at_the_company,
  business_description,
  city,
  password,
  password2,
  token,
}) => {
  const header = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }
  return axios.post(
    EDIT,
    {
      first_name,
      last_name,
      email,
      company_name,
      company_web_site,
      your_role_at_the_company,
      business_description,
      city,
      password,
      password2,
    },
    {
      headers: header,
    }
  )
}

const updateUser = (formData, token) => {
  const [
    first_name,
    last_name,
    email,
    city,
    company_name,
    company_web_site,
    business_description,
    your_role_at_the_company,
  ] = [
    formData.get('first_name'),
    formData.get('last_name'),
    formData.get('email'),
    formData.get('city'),
    formData.get('company_name'),
    formData.get('company_web_site'),
    formData.get('business_description'),
    formData.get('your_role_at_the_company'),
  ]

  const header = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  return axios.post(
    USER_UPDATE,
    {
      first_name,
      last_name,
      business_description,
      email,
      city,
      company_name,
      company_web_site,
      your_role_at_the_company,
    },
    {
      headers: header,
    }
  )
}

const login = ({ email, password }) => {
  const header = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }

  const params = new URLSearchParams()
  params.append('username', email)
  params.append('password', password)

  return axios.post(LOGIN, params, {
    headers: header,
  })
}

const analysisList = (token, firmSlug) => {
  const headers = token
    ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    : {
        'Content-Type': 'application/json',
      }
  return axios.get(
    `${process.env.REACT_APP_REST_URL}/analyze/${firmSlug}/list/`,
    {
      headers,
    }
  )
}

const analysisTablesByYearAndIndustry = (
  foreCastStartDate,
  decemberOfLastYear,
  foreCastEndDate,
  projection_frequency,
  industry,
  fiscalYear,
  token,
  firmId
) => {
  const header = token
    ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    : {
        'Content-Type': 'application/json',
      }
  return axios.post(
    token
      ? `${process.env.REACT_APP_REST_URL}/analyze/table/${firmId}/`
      : DEFAULT_ANALYSİS_TABLES_BY_YEAR_AND_INDUSTRY,
    {
      foreCastStartDate,
      decemberOfLastYear,
      foreCastEndDate,
      projection_frequency,
      fiscalYear,
      industry,
    },
    {
      headers: header,
    }
  )
}

const analysisTablesByYearAndIndustryDefault = (
 firm_background,
  token
) => {
  const header = token
    ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    : {
        'Content-Type': 'application/json',
      }
  return axios.post(
    token
      ? `${process.env.REACT_APP_REST_URL}/analyze/table/default/`
      : DEFAULT_ANALYSİS_TABLES_BY_YEAR_AND_INDUSTRY,
    {
      firm_background
    },
    {
      headers: header,
    }
  )
}

const analysisTablesByYearAndIndustryDefaultExcel = (
  foreCastStartDate,
  decemberOfLastYear,
  foreCastEndDate,
  projection_frequency,
  industry,
  data,
  token
) => {
  const header = token
    ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    : {
        'Content-Type': 'application/json',
      }
  return axios.post(
    token
      ? `${process.env.REACT_APP_REST_URL}/analyze/table/default/`
      : DEFAULT_ANALYSİS_TABLES_BY_YEAR_AND_INDUSTRY_EXCEL,
    {
      foreCastStartDate,
      decemberOfLastYear,
      foreCastEndDate,
      projection_frequency,
      industry,
      data,
    },
    {
      headers: header,
    }
  )
}

const analysis = (token, values) => {
  const headers = token
    ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    : {
        'Content-Type': 'application/json',
      }
  return axios.post(ANALYSIS, values, {
    headers,
  })
}

const analysisCreate = (token, firmSlug) => {
  return axios.post(
    `${process.env.REACT_APP_REST_URL}/analyze/${firmSlug}/create/`,
    {
      name: 'analysis',
      is_default: 1,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const firmBackgroundCreate = (token, values) => {
  console.log(values)
  return axios.post(FIRM_BACKGROUND, values, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

const realAnalysisCreate = (token, financialStatements, analysisId) => {
  return axios.post(
    `${process.env.REACT_APP_REST_URL}/analyze/real/${analysisId}/statements/`,
    financialStatements,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const realFxCreate = (token, newFx, analysisId) => {
  return axios.post(
    `${process.env.REACT_APP_REST_URL}/analyze/real/${analysisId}/fx-position/`,
    newFx,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const realMaturityCreate = (token, newMaturity, analysisId) => {
  return axios.post(
    `${process.env.REACT_APP_REST_URL}/analyze/real/${analysisId}/maturity/`,
    newMaturity,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const realBusinessCreate = (token, newBusinessPlan, analysisId) => {
  return axios.post(
    `${process.env.REACT_APP_REST_URL}/analyze/real/${analysisId}/business-plan/`,
    newBusinessPlan,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const financialAnalysisCreate = (token, financialStatements, analysisId) => {
  return axios.post(
    `${process.env.REACT_APP_REST_URL}/analyze/financial/${analysisId}/statements/`,
    financialStatements,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const financialFxCreate = (token, newFx, analysisId) => {
  return axios.post(
    `${process.env.REACT_APP_REST_URL}/analyze/financial/${analysisId}/fx-position/`,
    newFx,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const financialMaturityCreate = (token, newMaturity, analysisId) => {
  return axios.post(
    `${process.env.REACT_APP_REST_URL}/analyze/financial/${analysisId}/maturity/`,
    newMaturity,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const financialBusinessCreate = (token, newBusinessPlan, analysisId) => {
  return axios.post(
    `${process.env.REACT_APP_REST_URL}/analyze/financial/${analysisId}/business-plan/`,
    newBusinessPlan,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const customScenarioCreate = (token, custom, analysisId) => {
  return axios.post(
    `${process.env.REACT_APP_REST_URL}/analyze/scenario/${analysisId}/create/`,
    custom,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const customScenarioDataCreate = (
  token,
  newCustom,
  analysisId,
  scenariosId
) => {
  return axios.post(
    `${process.env.REACT_APP_REST_URL}/analyze/scenario/${analysisId}/${scenariosId}/create/`,
    newCustom,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const customScenarioDataUpdate = (
  token,
  newCustom,
  analysisId,
  scenariosId,
  id
) => {
  return axios.put(
    `${process.env.REACT_APP_REST_URL}/analyze/scenario/${analysisId}/${scenariosId}/${id}/update/`,
    newCustom,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const getCustomScenarioList = (token, analysisId, projection_frequency) => {
  return axios.get(
    `${process.env.REACT_APP_REST_URL}/analyze/scenario/${analysisId}/list/?projection_frequency=${projection_frequency}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const getCustomScenarioDataList = (
  token,
  analysisId,
  scenariosId,
  projection_frequency
) => {
  return axios.get(
    `${process.env.REACT_APP_REST_URL}/analyze/scenario/${analysisId}/${scenariosId}/list/`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        projection_frequency,
      },
    }
  )
}

const customScenarioDataDetail = (
  token,
  analysisId,
  scenariosId,
  id,
  projection_frequency,
  year
) => {
  return axios.get(
    `${process.env.REACT_APP_REST_URL}/analyze/scenario/${analysisId}/${scenariosId}/${id}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    },
    {
      params: {
        token,
        projection_frequency,
        year,
      },
    }
  )
}

// Get Data
const getFirmBackground = (token, firm) => {
  return axios.get(`${FIRM_BACKGROUND}/${firm}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

const createFirm = async (token, firmBackground) => {
  console.log(firmBackground, 'firmBackground')
  return axios.post(FIRM_CREATE, firmBackground, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

const getFirmDetail = (token, firmSlug) => {
  console.log(firmSlug, 'firmSlugfirmSlug')
  return axios.get(`${process.env.REACT_APP_REST_URL}/firm/${firmSlug}/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

const firmDelete = (token, firmSlug) => {
  return axios.delete(
    `${process.env.REACT_APP_REST_URL}/firm/${firmSlug}/delete/`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const updateFirm = (token, firmSlug, firmBackground) => {
  return axios.put(
    `${process.env.REACT_APP_REST_URL}/firm/${firmSlug}/update/`,
    firmBackground,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const getMyFirms = (token) => {
  return axios.get(MY_FIRMS, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
}

const getFirmList = (token) => {
  return axios.get(FIRM_LIST, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
}

const logOut = (token) => {
  return axios.get(LOGOUT, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
}
const resetPassword = (email, redirect_url) => {
  const body = JSON.stringify({ email, redirect_url })
  return axios.post(RESET_PASSWORD, body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

const resetPassworConfirm = (uidb64, resetToken) => {

  return axios.get(
    `${process.env.REACT_APP_REST_URL}/user/password-reset/${"MTQ"}/${"c7fomo-b306e0b60a642bcb4fb524ef496e0628"}/`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

const resetPasswordComplete = (uidb64, resetToken, newPassword) => {
  console.log(uidb64, resetToken, newPassword, 4545)
  return axios.patch(
    `${process.env.REACT_APP_REST_URL}/user/password-reset-complete`,
    { uidb64, token: resetToken, password: newPassword },
    {
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }
  )
}

const getLoanRequest = () => {
  return axios.get(`${process.env.REACT_APP_REST_URL}/loan/get-loan/`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

const loanRequestCreate = (token, createAll) => {
  return axios.post(
    `${process.env.REACT_APP_REST_URL}/loan/create/`,
    createAll,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const listLoanRequest = (token) => {
  return axios.get(`${process.env.REACT_APP_REST_URL}/loan/list/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

const updateLoanRequest = (token, loanId, updateAll) => {
  return axios.put(
    `${process.env.REACT_APP_REST_URL}/loan/loan-update/${loanId}/`,
    updateAll,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const loanDetail = (token, loanId) => {
  return axios.get(
    `${process.env.REACT_APP_REST_URL}/loan/get-loan-detail/${loanId}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const deleteLoanRequest = (token, id) => {
  return axios.delete(
    `${process.env.REACT_APP_REST_URL}/loan/loan-delete/${id}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const getEquityRequest = () => {
  return axios.get(`${process.env.REACT_APP_REST_URL}/equity/get-equity/`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

const equityRequestCreate = (token, createAll) => {
  return axios.post(
    `${process.env.REACT_APP_REST_URL}/equity/create/`,
    createAll,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const listEquityRequest = (token) => {
  return axios.get(`${process.env.REACT_APP_REST_URL}/equity/list/`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
}

const updateEquityRequest = (token, equityId, updateAll) => {
  return axios.put(
    `${process.env.REACT_APP_REST_URL}/equity/equity-update/${equityId}/`,
    updateAll,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const equityDetail = (token, equityId) => {
  return axios.get(
    `${process.env.REACT_APP_REST_URL}/equity/get-equity-detail/${equityId}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

const deleteEquityRequest = (token, id) => {
  return axios.delete(
    `${process.env.REACT_APP_REST_URL}/equity/equity-delete/${id}/`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

export {
  analysisTablesByYearAndIndustry,
  analysisTablesByYearAndIndustryDefault,
  analysis,
  analysisList,
  firmBackgroundCreate,
  realAnalysisCreate,
  realFxCreate,
  realMaturityCreate,
  realBusinessCreate,
  financialAnalysisCreate,
  financialFxCreate,
  financialMaturityCreate,
  financialBusinessCreate,
  analysisCreate,
  customScenarioCreate,
  getCustomScenarioList,
  customScenarioDataCreate,
  getFirmBackground,
  getFirmDetail,
  updateFirm,
  getCustomScenarioDataList,
  customScenarioDataDetail,
  customScenarioDataUpdate,
  updateUser,
  registration,
  login,
  createFirm,
  getMyFirms,
  getFirmList,
  firmDelete,
  edit,
  logOut,
  resetPassword,
  resetPassworConfirm,
  resetPasswordComplete,
  getLoanRequest,
  loanRequestCreate,
  listLoanRequest,
  loanDetail,
  updateLoanRequest,
  deleteLoanRequest,
  getEquityRequest,
  equityRequestCreate,
  listEquityRequest,
  updateEquityRequest,
  equityDetail,
  deleteEquityRequest,
  analysisTablesByYearAndIndustryDefaultExcel,
}
