import React, { useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TabsRouter from 'components/TabsRouter';
import { useDispatch, useSelector } from 'react-redux';
import Firmbackground from './Firmbackground';
import FinancialStatements from './FinancialStatements';
import FxPosition from './FxPosition';
import MaturityShortTerm from './MaturityShortTerm';
import BusinessPlan from './BusinessPlan';
import CustomScenario from './CustomScenario';
import Report from './Report';
import ProgressTableAndExcelExport from 'components/ProgressTableAndExcelExport';
import AnalysisButtons from 'components/AnalysisButtons';
import { getFirmList } from 'redux/ApiCalls';
import { useIntl } from 'react-intl';
import { Header } from 'components/layout';
import storeConstants from 'redux/constants/actionTypes';
import ScrollLeftAndRight from 'components/ScrollLeftAndRight';

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function AnalysisPage() {
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState(0);
  const { tabsRouter } = useSelector((state) => state.analysisDataReducer);
  const [excelRead, setExcelRead] = useState(null);
  const { isOpen } = useSelector((state) => state.drawerOpenAndClosed);
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();

  useEffect(() => {
    if (tab === 1) {
      getFirmList(token)
        .then((res) => {
          dispatch({
            type: storeConstants.GET_FIRM_LIST,
            payload: res.data.results,
          });
        })
        .catch((e) => console.log(e));
    }
  }, [tab, token, dispatch]);

  useEffect(() => {
    if (tabsRouter) {
      setTab(0);
    }
  }, [tabsRouter]);

  const handleDrawerOpen = () => {
    dispatch({
      type: storeConstants.OPEN_DRAWER,
      payload: true,
    });
  };

  const handleDrawerClose = () => {
    dispatch({
      type: storeConstants.CLOSE_DRAWER,
      payload: false,
    });
  };

  const tabs = [
    { label: 'Firm Background', component: <Firmbackground changePage={() => setTab(1)} /> },
    { label: 'Financial Statements', component: <FinancialStatements excelRead={excelRead} isLoading={isLoading} setIsLoading={setIsLoading} /> },
    { label: 'FX Position', component: <FxPosition /> },
    { label: 'Maturity Short Term', component: <MaturityShortTerm /> },
    { label: 'Business Plan', component: <BusinessPlan /> },
    { label: 'Custom Scenario', component: <CustomScenario /> },
    { label: 'Report', component: <Report /> },
  ];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CssBaseline />
      <Drawer className="drawer" variant="permanent" open={isOpen}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '0px 8px' }}>
          {isOpen ? (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <MenuIcon />
            </IconButton>
          )}
        </div>
        <Divider />
        <List className="drawer">
          <TabsRouter changePage={() => setTab(1)} activeTab={tab} setActiveTab={setTab} isLoading={isLoading} setIsLoading={setIsLoading} />
        </List>
        <Divider />
      </Drawer>

      <Box component="main" className="main-analysis" sx={{ flexGrow: 1, p: '2rem', maxWidth: '1300px' }}>
        {tab !== 0 && (
          <div className={`table-header__cell ${'sticky-col'}`}>
            <ProgressTableAndExcelExport excelRead={excelRead} setExcelRead={setExcelRead} />
          </div>
        )}
        {tab === 0 && <Firmbackground changePage={() => setTab(1)} />}
        {tab > 0 && tab < 7 && (
          <React.Fragment>
            <ScrollLeftAndRight
              excelRead={excelRead}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              Component={tabs[tab]?.component}
            />
          </React.Fragment>
        )}
        {tab !== 0 && <AnalysisButtons activeTab={tab} setActiveTab={setTab} />}
      </Box>
    </Box>
  );
}