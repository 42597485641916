import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import ReactTooltip from 'react-tooltip'
import loanStyles from 'assets/styles/pages/loan.module.scss'
import { deleteEquityRequest, getFirmList, listEquityRequest } from 'redux/ApiCalls'
import { useDispatch, useSelector } from 'react-redux'
import storeConstants from 'redux/constants/actionTypes'


const Equity = () => {
    const intl = useIntl()
    const token = useSelector((state) => state.auth.token)
    const data = useSelector((state) => state.equityRequest.equity)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if (token) {
            listEquityRequest(token)
                .then((res) => {
                    dispatch({
                        type: storeConstants.GET_EQUITY,
                        payload: res.data,
                    })
                })
                .catch((e) => console.log(e))
        }

    }, [])

    useEffect(() => {
        getFirmList(token)
            .then((res) => {
                dispatch({
                    type: storeConstants.GET_FIRM_LIST,
                    payload: res.data.results,
                })
            })
            .catch((e) => console.log(e))
    }, [])


    return (
        <div className='container  mt-3'>
            <table className={loanStyles.table} id={loanStyles.table_equity_table}>
                <thead>
                    <tr className={loanStyles.equity_header}>
                        <th className={loanStyles.equity_header_item} > {
                            intl.formatMessage({
                                id: 'LOAN_HEADER_FOR_FIRM',
                            })
                        }</th>
                        <th className={loanStyles.equity_header_item}> {
                            intl.formatMessage({
                                id: 'EQUITY_HEADER_TYPE_OF_CAPITAL',
                            })
                        }</th>
                        <th className={loanStyles.equity_header_item} > {
                            intl.formatMessage({
                                id: 'EQUITY_HEADER_AMOUNT',
                            })
                        }</th>
                        <th className={loanStyles.equity_header_item} > {
                            intl.formatMessage({
                                id: 'EQUITY_HEADER_VALUATION',
                            })
                        }</th>

                        <th className={loanStyles.equity_header_item} >  {
                            intl.formatMessage({
                                id: 'LOAN__HEADER_SPECIAL_NOTES',
                            })
                        }</th>
                        <th className={loanStyles.equity_header_item}>
                            {
                                intl.formatMessage({
                                    id: 'LOAN_HEADER_PRIVATE',
                                })
                            }</th>
                        <th className="btn btn-primary analysis_button" id={loanStyles.equity_header_item_add_button} onClick={() => { navigate("/get-equity") }} data-tip={"New request"} ><i style={{ fontSize: "20px" }} className="fa fa-plus" aria-hidden="true" ></i></th>
                        <ReactTooltip />
                    </tr>
                </thead>

                {
                    data && Object.keys(data).map((key) => (
                        <tbody>
                            <tr className={loanStyles.equity_content} key={key}>
                                <td className={loanStyles.equity_content_item}>{data[key]?.firm ? data[key]?.firm : "Other"}</td>
                                <td className={loanStyles.equity_content_item}>{data[key].type_of_capital.map(item => {
                                    return <ul><li>{item}</li></ul>
                                })}</td>
                                <td className={loanStyles.equity_content_item}>{data[key].amount_of_capital_to_raise}</td>
                                <td className={loanStyles.equity_content_item}>{data[key].valuation}</td>
                                <td className={loanStyles.equity_content_item}>{data[key].special_notes}</td>
                                <td className={loanStyles.equity_content_item}>{data[key].private}</td>
                                <td className={loanStyles.equity_content_item} id={loanStyles.edit_and_delete} style={{ background: "red !important" }} >
                                    <td className="btn btn-primary analysis_button" id={loanStyles.equity_content_item_update}
                                        onClick={() => {
                                            navigate(`/equity-detail/${data[key].id}`)
                                        }} data-tip={"Update"}><i style={{ fontSize: "20px" }} className="fa fa-edit"></i>
                                    </td>
                                    <ReactTooltip />
                                    <td className="btn btn-primary analysis_button" id={loanStyles.equity_content_item_delete} onClick={() => {
                                        deleteEquityRequest(token, data[key].id)
                                            .then((res) => {
                                                listEquityRequest(token)
                                                    .then((res) => {
                                                        dispatch({
                                                            type: storeConstants.GET_EQUITY,
                                                            payload: res.data,
                                                        })
                                                    })
                                                    .catch((e) => console.log(e))
                                            })
                                    }} data-tip={"Delete"}><i style={{ fontSize: "20px" }} className="fa fa-trash" aria-hidden="true"></i></td>
                                    <ReactTooltip />
                                </td>
                            </tr>
                        </tbody>
                    ))
                }
            </table>
        </div >

    )
}

export default Equity