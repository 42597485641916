import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Bar, Line } from 'react-chartjs-2'
import { useIntl } from 'react-intl'
import styles from 'assets/styles/pages/report.module.scss'
import ReportFirmbackground from './ReportFirmbackground'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const indicatorsBackgroundColor = [
  'rgba(255, 99, 132, 4)',
  'rgba(54, 162, 235, 4)',
  'rgba(255, 206, 86, 4)',
  'rgba(75, 192, 192, 4)',
  'rgba(153, 102, 255, 4)',
  'rgba(255, 159, 64, 4)',
  'rgba(255, 99, 132, 4)',
]

const indicatorsBackgroundColor2 = [
  'rgba(54, 162, 235, 4)',
  'rgba(255, 99, 132, 4)',
  'rgba(255, 206, 86, 4)',
  'rgba(75, 192, 192, 4)',
  'rgba(153, 102, 255, 4)',
  'rgba(255, 159, 64, 4)',
  'rgba(255, 99, 132, 4)',
]
const RealAnalysisReport = () => {
  const intl = useIntl()
  const [chartDatas, setChartDatas] = useState({})
  const [chartIndicatorsDatas, setChartIndicatorsDatas] = useState({})

  const { analysisResult, firmBackground } = useSelector(
    (state) => state.analysisDataReducer
  )

  const {
    custom_financial_statements,
    financial_statements,
    enable_custom_scenario,
  } = analysisResult

  const labels = []

  analysisResult.financial_statements.map((datas) => {
    labels.push(datas.year)
  })

  useEffect(() => {
    indicatorsTables([
      'Growth Rate Of Revenues',
      'Gross Margin',
      'EBITDA Margin',
      'Profit Margin',
      'ICR',
      'DSCR',
      'ROA',
      'ROE',
      'Leverage Ratio',
    ])
  }, [])

  const indicatorsTables = (keyArrays) => {
    let newChartData = {}

    keyArrays.map((key) => {
      const dataSet = []

      const baselineDataSet = {
        backgroundColor: indicatorsBackgroundColor,
        fill: false,
        label: 'Baseline Scenario',
        title: 'test',
        chartType: 'line',
        data: [],
      }
      const customDataSet = {
        backgroundColor: indicatorsBackgroundColor,
        fill: false,
        label: 'Custom Scenario',
        title: 'test',
        chartType: 'line',
        data: [],
      }
      analysisResult.indicators.map((datas) => {
        const indicatorsData = datas.values.find((item) => item.label === key)
        baselineDataSet.data.push(indicatorsData.value)
      })
      dataSet.push(baselineDataSet)

      if (enable_custom_scenario) {
        analysisResult.custom_indicators.map((datas) => {
          const indicatorsData = datas.values.find((item) => item.label === key)
          customDataSet.data.push(indicatorsData.value)
        })
        dataSet.push(customDataSet)
      }

      newChartData[key] = { labels, datasets: dataSet }
    })
    setChartIndicatorsDatas(newChartData)
  }

  useEffect(() => {
    financialAnalysisFieldTable(['total_assets', 'net_income'])
  }, [])

  const financialAnalysisFieldTable = (keyArrays) => {
    let newChartData = {}

    keyArrays.map((key) => {
      const dataSet = []

      const baselineDataSet = {
        backgroundColor: indicatorsBackgroundColor,
        label: 'Baseline Scenario',
        borderColor: 'white',
        data: [],
      }
      const customDataSet = {
        backgroundColor: indicatorsBackgroundColor,
        label: 'Custom Scenario',
        borderColor: 'white',
        data: [],
      }
      analysisResult.financial_statements.map((datas) => {
        const totalAsetValue = datas.values.find((item) => item.key === key)
        baselineDataSet.data.push(totalAsetValue.value)
      })

      dataSet.push(baselineDataSet)

      if (enable_custom_scenario) {
        analysisResult.custom_financial_statements.map((datas) => {
          const totalAsetValue = datas.values.find((item) => item.key === key)
          customDataSet.data.push(totalAsetValue.value)
        })
        dataSet.push(customDataSet)
      }

      newChartData[key] = { labels, datasets: dataSet }
    })
    setChartDatas(newChartData)
  }

  function createChartOptions(titleText) {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: titleText,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    }
  }

  const optionsTotalAssets = createChartOptions('Total Assets')
  const optionsNetIncome = createChartOptions('Net Income')
  const optionsGrowths = createChartOptions('Growth Rate Of Revenues (%)')
  const optionsGross = createChartOptions('Gross Margin (%)')
  const optionsEbıdta = createChartOptions('EBITDA Margin (%)')
  const optionsProfitMargin = createChartOptions('Profit Margin (%)')
  const optionsIcr = createChartOptions('ICR (%)')
  const optionsDscr = createChartOptions('DSCR (%)')
  const optionsRoa = createChartOptions('ROA (%)')
  const optionsRoe = createChartOptions('ROE (%)')
  const optionsLaverege = createChartOptions('Leverage Ratio')

  const table = (
    <div>
      <table className="rtable">
        <thead>
          <tr>
            <th className="fixed-row" style={{ width: '300px' }}>
              <span
                className="financial-main-table-fixed-row-title"
                style={{ color: '#012169' }}
              >
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE',
                })}
              </span>
            </th>

            {analysisResult?.financial_statements?.map(
              (yearData, columnIndex) => {
                yearData.year.split('-')
                const date = new Date(
                  Number(yearData.year.split('-')[0]),
                  Number(yearData.year.split('-')[1]),
                  0
                )
                return (
                  <th scope="col" key={columnIndex}>
                    {date.toLocaleString('en-US', {
                      year: 'numeric',
                    }) +
                      '-' +
                      date.toLocaleString('en-US', {
                        month: 'short',
                      })}
                  </th>
                )
              }
            )}
          </tr>
        </thead>
        <tbody>
          {financial_statements[0]?.values.slice(0, 11).map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td
                className={`fixed-row ${
                  row.label === 'Total Assets' ||
                  row.label === 'Total Liabilities And Equity'
                    ? 'fw-bold'
                    : ''
                }`}
              >
                {intl.formatMessage({
                  id: row.key,
                })}
              </td>
              {analysisResult?.financial_statements?.map(
                (yearData, columnIndex) => (
                  <>
                    <td key={columnIndex}>
                      <input
                        type="text"
                        defaultValue={0}
                        value={yearData.values[rowIndex].value}
                        disabled
                      />
                    </td>
                  </>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <table className="rtable">
        <thead>
          <tr>
            <th className="fixed-row" style={{ width: '300px' }}>
              <span style={{ color: '#012169' }}>
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE',
                })}
              </span>
            </th>
            {analysisResult?.financial_statements?.map(
              (yearData, columnIndex) => {
                yearData.year.split('-')
                const date = new Date(
                  Number(yearData.year.split('-')[0]),
                  Number(yearData.year.split('-')[1]),
                  0
                )
                return (
                  <th scope="col" key={columnIndex}>
                    {date.toLocaleString('en-US', {
                      year: 'numeric',
                    }) +
                      '-' +
                      date.toLocaleString('en-US', {
                        month: 'short',
                      })}
                  </th>
                )
              }
            )}
          </tr>
        </thead>
        <tbody>
          {financial_statements[0]?.values
            .slice(12, financial_statements[0].values.length)
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td
                  className={`fixed-row ${
                    row.label === 'Net Income' ? 'fw-bold' : ''
                  }`}
                >
                  {intl.formatMessage({
                    id: row.key,
                  })}
                </td>
                {analysisResult?.financial_statements?.map(
                  (yearData, columnIndex) => (
                    <>
                      <td key={columnIndex}>
                        <input
                          type="text"
                          defaultValue={0}
                          value={yearData?.values[rowIndex + 12].value}
                          disabled
                        />
                      </td>
                    </>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
  const customTable = custom_financial_statements[0]?.values && (
    <div>
      <table className="rtable">
        <thead>
          <tr>
            <th style={{ width: '300px' }}>
              <span
                className="financial-main-table-fixed-row-title"
                style={{ color: '#012169' }}
              >
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE',
                })}
              </span>
            </th>
            {custom_financial_statements?.map((yearData, columnIndex) => {
              yearData.year.split('-')
              const date = new Date(
                Number(yearData.year.split('-')[0]),
                Number(yearData.year.split('-')[1]),
                0
              )
              return (
                <th scope="col" key={columnIndex}>
                  {date.toLocaleString('en-US', {
                    year: 'numeric',
                  }) +
                    '-' +
                    date.toLocaleString('en-US', {
                      month: 'short',
                    })}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {custom_financial_statements[0]?.values
            .slice(0, 11)
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td
                  className={
                    row.label === 'Total Assets' ||
                    row.label === 'Total Liabilities And Equity'
                      ? 'fw-bold'
                      : ''
                  }
                >
                  {row.label}
                </td>
                {analysisResult?.custom_financial_statements?.map(
                  (yearData, columnIndex) => (
                    <>
                      <td key={columnIndex}>
                        <input
                          type="text"
                          defaultValue={0}
                          value={yearData.values[rowIndex].value}
                          disabled
                        />
                      </td>
                    </>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
      <table className="rtable">
        <thead>
          <tr>
            <th style={{ width: '300px' }}>
              <span style={{ color: '#012169' }}>
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE',
                })}
              </span>
            </th>
            {custom_financial_statements?.map((yearData, columnIndex) => {
              yearData.year.split('-')
              const date = new Date(
                Number(yearData.year.split('-')[0]),
                Number(yearData.year.split('-')[1]),
                0
              )
              return (
                <th scope="col" key={columnIndex}>
                  {date.toLocaleString('en-US', {
                    year: 'numeric',
                  }) +
                    '-' +
                    date.toLocaleString('en-US', {
                      month: 'short',
                    })}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {custom_financial_statements[0]?.values
            .slice(12, custom_financial_statements[0].values.length)
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className={row.label === 'Net Income' ? 'fw-bold' : ''}>
                  {row.label}
                </td>
                {analysisResult?.custom_financial_statements?.map(
                  (yearData, columnIndex) => (
                    <>
                      <td key={columnIndex}>
                        <input
                          type="text"
                          defaultValue={0}
                          value={yearData?.values[rowIndex + 12].value}
                          disabled
                        />
                      </td>
                    </>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )

  return (
    <div className={styles.report_container}>
      <div
        className={`${
          styles.report_container_main_header
        } ${'col-12 text-center mt-5 mb-3'}`}
      >
        Forecast Report
      </div>
      <ReportFirmbackground />
      <div
        className={`${
          styles.report_container_main_header_common
        } ${'col-12 text-left mt-3 '}`}
      >
        II. Forecast Summary
      </div>
      {analysisResult?.financial_statements &&
      chartIndicatorsDatas['Profit Margin'] &&
      chartDatas ? (
        <div className="container common-canvas">
          <div className="row justify-content-center mt-5">
            <div className="col-6">
              <Line
                options={optionsTotalAssets}
                data={chartDatas.total_assets}
              />
            </div>
            <div className="col-6">
              <Line options={optionsNetIncome} data={chartDatas.net_income} />
            </div>
          </div>

          <div className="row justify-content-center mt-5">
            <div className="col-6">
              <Bar
                options={optionsGrowths}
                data={chartIndicatorsDatas['Growth Rate Of Revenues']}
              />
            </div>
            <div className="col-6">
              <Bar
                options={optionsGross}
                data={chartIndicatorsDatas['Gross Margin']}
              />
            </div>
          </div>

          <div className="row justify-content-center mt-5">
            <div className="col-6">
              <Bar
                options={optionsEbıdta}
                data={chartIndicatorsDatas['EBITDA Margin']}
              />
            </div>
            <div className="col-6">
              <Bar
                options={optionsProfitMargin}
                data={chartIndicatorsDatas['Profit Margin']}
              />
            </div>
          </div>

          <div className="row justify-content-center mt-5">
            <div className="col-6">
              <Bar options={optionsIcr} data={chartIndicatorsDatas['ICR']} />
            </div>
            <div className="col-6">
              <Bar options={optionsDscr} data={chartIndicatorsDatas['DSCR']} />
            </div>
          </div>

          <div className="row justify-content-center mt-5">
            <div className="col-6">
              <Bar options={optionsRoa} data={chartIndicatorsDatas['ROA']} />
            </div>
            <div className="col-6">
              <Bar options={optionsRoe} data={chartIndicatorsDatas['ROE']} />
            </div>
          </div>

          <div className="row  mt-5">
            <div className="col-6">
              <Bar
                options={optionsLaverege}
                data={chartIndicatorsDatas['Leverage Ratio']}
              />
            </div>
          </div>
          {analysisResult ? (
            <div className="container">
              <div className="analysis-table-header-container">
                <div className="analysis-table-header-container-sub">
                  <div className="analysis-table-header-container-table">
                    <div className="analysis-table-header-container-sub-text">
                      <div
                        className={`${
                          styles.report_container_main_header_common
                        } ${'col-12 text-left mt-5 mb-3'}`}
                        style={{ width: '98%' }}
                      >
                        III.{' '}
                        {intl.formatMessage({
                          id: 'FINANCIAL_CHART.TITLE',
                        })}
                      </div>
                    </div>
                    {table}
                  </div>
                </div>
              </div>

              <br />

              <div className="analysis-table-header-container">
                <div className="analysis-table-header-container-sub">
                  <div
                    className="analysis-table-header-container-sub-text"
                    style={{ width: '100%' }}
                  >
                    {custom_financial_statements[0]?.values && (
                      <div
                        className={`${
                          styles.report_container_main_header_common
                        } ${'col-12 text-left mt-5 mb-3'}`}
                        style={{ width: '98%' }}
                      >
                        IV.{' '}
                        {intl.formatMessage({
                          id: 'FINANCIAL_CHART.CUSTOM_TABLE_TITLE',
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="analysis-table-header-container-sub">
                  <div className="analysis-table-header-container-table">
                    {customTable}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
export default RealAnalysisReport
