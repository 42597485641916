import AnimalSections from 'components/AnimalSections'
import AppAppBar from 'components/AppAppBar'
import FAQ from 'components/FAQ'
import Features from 'components/Features'
import Footer from 'components/Footer'
import Footers from 'components/Footers'
import Hero from 'components/Hero'
import Highlights from 'components/Highlights'
import HomeBackground from 'components/HomeBackground'
import HomeButtonGroup from 'components/HomeButtonGroup'
import Testimonials from 'components/Testimonials'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFirmList } from 'redux/ApiCalls'
import storeConstants from 'redux/constants/actionTypes'

const HomePage = () => {
  const { token } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    getFirmList(token)
      .then((res) => {
        dispatch({
          type: storeConstants.GET_FIRM_LIST,
          payload: res.data.results,
        })
      })
      .catch((e) => console.log(e))
  }, [])

  return (
    <div>
      <Hero />
      {/* <Features /> */}
      <AnimalSections />

      {/* <Testimonials /> */}
      {/* <Highlights /> */}
      <Footers />
      {/* <HomeBackground /> */}
      {/* <HomeButtonGroup /> */}
      {/* <Footer /> */}
    </div>
  )
}

export default HomePage
