import React, { useState } from 'react';
import { resetPassword } from 'redux/ApiCalls';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
const theme = createTheme();

const ResetPassword = () => {
    const intl = useIntl()
    const [formData, setFormData] = useState({
        email: ''
    });

    const [show, setShow] = useState(false);

    const { email } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });



    const onSubmit = e => {
        e.preventDefault();
        // const redirect_url = "https://api.equlion.com/api/v1/password-reset-confirm"
        const redirect_url = "http://127.0.0.1:8000/api/v1/user/password-reset-confirm"
        console.log("redirect_url",redirect_url)

        // "https://equlion.com/password-reset-confirm"
        resetPassword(email, redirect_url);
    };


    const trueOrFalse = () => {
        if (email === '') {
            return true;
        } else {
            return false;
        }
    }


    const showOnClick = () => {
        console.log(show)
        if (email !== '') {
            console.log(!show)
            setShow(true);
        }
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <div className="main-login">
                    <Container component="main" maxWidth="xs">
                        <a
                            className="button-equlion"
                            style={{
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textDecoration: 'none',
                            }}
                        >
                            <span style={{ color: 'white' }}>Equ</span>
                            <span className="header-menu-logo-lion" style={{ color: '#f73681' }}>
                                <span style={{ fontSize: '40px' }}>l</span>ion
                            </span>
                        </a>
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, background: "Transparent" }}>
                            </Avatar>
                            <Box className="auth_form" component="form" noValidate sx={{ mt: 3 }} onSubmit={e => onSubmit(e)}>
                                <svg className="signup-sides">
                                    <line className="top-right first" x1="50%" x2="100%" y1="0" y2="0" />
                                    <line className="top-left first" x1="50%" x2="0" y1="0" y2="0" />
                                    <line className="right second" x1="100%" x2="100%" y1="0%" y2="100%" />
                                    <line className="left second" x1="0" x2="0" y1="0" y2="100%" />
                                    <line className="bottom-left third" x1="0" x2="50%" y1="100%" y2="100%" />
                                    <line className="bottom-right third" x1="100%" x2="50%" y1="100%" y2="100%" />
                                </svg>

                                {!show &&

                                    <Grid container justifyContent="flex-start">
                                        <Grid item>
                                            <Typography  >
                                                {
                                                    intl.formatMessage({
                                                        id: "RESET_PASSWORD.TEXT"
                                                    })
                                                }
                                            </Typography >
                                        </Grid>
                                    </Grid>
                                }
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            autoComplete="given-name"
                                            name="email"
                                            type='email'
                                            required
                                            fullWidth
                                            size="small"
                                            id="email"
                                            onChange={onChange}
                                            label={intl.formatMessage({
                                                id: 'REGISTER.EMAIL',
                                            })}
                                            autoFocus
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    size="small"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    onClick={showOnClick}
                                >
                                    {intl.formatMessage({
                                        id: 'RESET_PASSWORD.BUTTON',
                                    })}
                                </Button>
                                {show &&
                                    <Grid item>
                                        <Typography >
                                            {
                                                intl.formatMessage({
                                                    id: "RESET_PASSWORD.TEXT_ALERT"
                                                })
                                            }
                                        </Typography>
                                    </Grid>

                                }
                            </Box>
                        </Box>
                    </Container>
                </div>
            </ThemeProvider>
        </>
    )
};

export default ResetPassword