import storeConstants from '../constants/actionTypes'

const inintialState = {
  firms: [],
  firmSlug: [],
}

const getFirmList = (state = inintialState, action) => {
  switch (action.type) {
    case storeConstants.FIRM_SLUG:
      return {
        ...state,
        firmSlug: action.payload,
      }
    case storeConstants.GET_FIRM_LIST:
      return {
        ...state,
        firms: action.payload,
      }
    default:
      return state
  }
}

export default getFirmList
