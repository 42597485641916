// import React, { useEffect, useState } from 'react';
// import { Container, Nav, Navbar } from 'react-bootstrap';
// import { useIntl } from 'react-intl';
// import { useDispatch, useSelector } from 'react-redux';
// import CountryFlag from 'components/CountryFlag';
// import { logOut } from 'redux/actions/auth';
// import { toast } from 'react-toastify';
// import { Link, useNavigate } from 'react-router-dom';
// import Box from '@mui/material/Box';
// import SwipeableDrawer from '@mui/material/SwipeableDrawer';
// import Button from '@mui/material/Button';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
// import styles from '.././../assets/styles/components/accordion/accordion.module.scss';
// import storeConstants from 'redux/constants/actionTypes';
// import { firmDelete, getFirmList } from 'redux/ApiCalls';
// import MuiAppBar from '@mui/material/AppBar';
// import Drawer from '@mui/material/Drawer';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import { styled } from '@mui/material/styles';
// import { CssBaseline } from '@mui/material';

// const Header = () => {
//   const intl = useIntl();
//   const auth = useSelector((state) => state.auth);
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { firms } = useSelector((state) => state.getFirmList);
//   const { isOpen } = useSelector((state) => state.drawerOpenAndClosed);

//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [activeItem, setActiveItem] = useState(
//     localStorage.getItem('activeItem') || ''
//   );
//   const [drawerWidth, setDrawerWidth] = useState(0);

//   useEffect(() => {
//     localStorage.setItem('activeItem', activeItem);
//   }, [activeItem]);

//   useEffect(() => {
//     getFirmList(auth.token)
//       .then((res) => {
//         dispatch({
//           type: storeConstants.GET_FIRM_LIST,
//           payload: res.data.results,
//         });
//       })
//       .catch((e) => console.log(e));
//   }, [dispatch, auth.token]);

//   useEffect(() => {
//     setDrawerWidth(240);
//   }, [activeItem]);

//   const changeLocation = (placeToGo) => {
//     navigate(placeToGo, { replace: true });
//     window.location.reload();
//   };

//   const toggleDrawer = (open) => (event) => {
//     if (
//       event &&
//       event.type === 'keydown' &&
//       (event.key === 'Tab' || event.key === 'Shift')
//     ) {
//       return;
//     }
//     setDrawerOpen(open);
//   };

//   const handleDrawerClose = () => {
//     dispatch({
//       type: storeConstants.CLOSE_DRAWER,
//       payload: false,
//     });
//   };

//   const handleDrawerOpen = () => {
//     dispatch({
//       type: storeConstants.OPEN_DRAWER,
//       payload: true,
//     });
//   };

//   console.log("openvopen",open)

//   const AppBar = styled(MuiAppBar, {
//     shouldForwardProp: (prop) => prop !== 'open',
//   })(({ theme, open }) => ({
//     zIndex: theme.zIndex.drawer + 1,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     ...(open && {
//       marginLeft: drawerWidth,
//       width: `calc(100% - ${drawerWidth}px)`,
//       transition: theme.transitions.create(['width', 'margin'], {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//     }),
//   }));

//   const list = (
//     <Box
//       sx={{ width: 250 }}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         {firms.map((firm) => (
//           <ListItem key={firm} disablePadding>
//             <div className={styles.link_and_delete}>
//               <Link
//                 className={styles.link_and_delete_link}
//                 to={`/analysis/${firm.slug}`}
//               >
//                 <Button
//                   className={styles.link_and_delete_link_button}
//                   variant="contained"
//                   onClick={() => {
//                     dispatch({
//                       type: storeConstants.TABS_ROUTER,
//                       payload: true,
//                     });
//                   }}
//                   size="small"
//                 >
//                   <ListItemText primary={firm.name} />
//                 </Button>
//               </Link>
//               <div className={styles.link_and_delete_delete}>
//                 <Button
//                   className={styles.link_and_delete_delete_button}
//                   onClick={() => {
//                     firmDelete(auth.token, firm.slug).then(() => {
//                       toast.success(`${firm.name} deleted`);
//                       getFirmList(auth.token)
//                         .then((res) => {
//                           dispatch({
//                             type: storeConstants.GET_FIRM_LIST,
//                             payload: res.data.results,
//                           });
//                           changeLocation('/analysis');
//                         })
//                         .catch((e) => console.log(e));
//                     });
//                   }}
//                   size="small"
//                   variant="outlined"
//                   color="error"
//                 >
//                   <i
//                     style={{ fontSize: '20px' }}
//                     className="fa fa-trash"
//                     aria-hidden="true"
//                   ></i>
//                 </Button>
//               </div>
//             </div>
//           </ListItem>
//         ))}
//       </List>
//       <Link
//         id={styles.accordion_main_container_content_add_link}
//         to="/analysis"
//         onClick={() => changeLocation('/analysis')}
//       >
//         <Button
//           type="submit"
//           fullWidth
//           size="small"
//           variant="contained"
//           id={styles.accordion_main_container_content_add_button}
//         >
//           {intl.formatMessage({
//             id: 'FIRMACCORDION.BUTTON_CREATE',
//           })}
//           <i className="fa fa-plus" aria-hidden="true"></i>
//         </Button>
//       </Link>
//     </Box>
//   );

//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     const mediaQuery = window.matchMedia('(max-width: 768px)');
//     setIsMobile(mediaQuery.matches);

//     const handler = (e) => setIsMobile(e.matches);
//     mediaQuery.addListener(handler);

//     return () => mediaQuery.removeListener(handler);
//   }, []);

//   const handleItemClick = (itemName) => {
//     setActiveItem(itemName);
//     localStorage.setItem('activeItem', itemName);
//   };

//   return (
//     <>
//   {isMobile ? (
//   <>
//   <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>


//   <Button
//               href="/homepage"
//               onClick={() => {
//                 handleItemClick('homepage');
//                 setDrawerOpen(true);
//               }}
//               className={activeItem === 'homepage' ? 'active-nav-link' : ''}
//               style={{color:"black"}}
//             >
//                FinancialPredictionAI
             
//    </Button>
//    <div style={{display:"flex"}}>
//    <IconButton onClick={handleDrawerOpen}>
//     <MenuIcon />
//   </IconButton>
//   <CountryFlag />
//   </div>

//   <Drawer anchor="top" open={isOpen} onClose={handleDrawerClose} >
    
//     <div
//       onClick={handleDrawerClose}
//       onKeyDown={handleDrawerClose}
//     >
//       <List style={{width:"100%",display:"flex",justifyContent:"space-between", flexDirection:"column"}}>
//       {auth.token ? (
//               <>
//                 <Button
//                   color="inherit"
//                   onClick={() => handleItemClick('analysis')}
//                   active={activeItem === 'analysis'}
//                   className={activeItem === 'analysis' ? 'active-nav-link' : ''}
//                 >
//                   {intl.formatMessage({
//                     id: 'NAV.BUTTON.ANALYSIS',
//                   })}
//                 </Button>
//                 <SwipeableDrawer
//                   anchor="left"
//                   open={drawerOpen}
//                   onClose={() => setDrawerOpen(false)}
//                   onOpen={() => setDrawerOpen(true)}
//                 >
//                   {list}
//                 </SwipeableDrawer>
//               </>
//             ) : (
//               <Button
//                 color="inherit"
//                 href="/analysis"
//                 className={activeItem === 'analysis' ? 'active-nav-link' : ''}
//                 active={activeItem === 'analysis'}
//                 onClick={() => handleItemClick('analysis')}
//               >
//                 {intl.formatMessage({
//                   id: 'NAV.BUTTON.ANALYSIS',
//                 })}
//               </Button>
//             )}
//             {auth.token ? (
//               <Button
//                 color="inherit"
//                 onClick={() => handleItemClick('loan')}
//                 active={activeItem === 'loan'}
//                 className={activeItem === 'loan' ? 'active-nav-link' : ''}
//                 href="/loan"
//               >
//                 {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}
//               </Button>
//             ) : (
//               <Button color="inherit" href="/get-loan" onClick={() => handleItemClick('get-loan')} active={activeItem === 'get-loan'}
//                 className={activeItem === 'get-loan' ? 'active-nav-link' : ''}>
//                 {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}
//               </Button>
//             )}
//             {auth.token ? (
//               <Button
//                 color="inherit"
//                 onClick={() => handleItemClick('equity')}
//                 active={activeItem === 'equity'}
//                 className={activeItem === 'equity' ? 'active-nav-link' : ''}
//                 href="/equity"
//               >
//                 {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
//               </Button>
//             ) : (
//               <Button color="inherit" onClick={() => handleItemClick('get-equity')}
//                 active={activeItem === 'get-equity'}
//                 className={activeItem === 'get-equity' ? 'active-nav-link' : ''} href="/get-equity">
//                 {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
//               </Button>
//             )}
//             {auth.token ? (
//               <>
//                 <Button color="inherit"
//                   onClick={() => handleItemClick('myfirm')}
//                   active={activeItem === 'myfirm'}
//                   className={activeItem === 'myfirm' ? 'active-nav-link' : ''}
//                   href="/myfirm"
//                 >
//                   {intl.formatMessage({
//                     id: 'NAV.BUTTON.MY_FIRM',
//                   })}
//                 </Button>
//                 <Button color="inherit"
//                   onClick={() => handleItemClick('myfirm-update')}
//                   active={activeItem === 'myfirm-update'}
//                   className={activeItem === 'myfirm-update' ? 'active-nav-link' : ''}
//                   href="/myfirm-update"
//                 >
//                   {intl.formatMessage({ id: 'SIDEBAR_ROUTES.ACCOUNT' })}
//                 </Button>
//                 <Button color="inherit"
//                   onClick={() =>
//                     dispatch(
//                       logOut(),
//                       navigate('/homepage'),
//                       toast.info('successfully logged out')
//                     )
//                   }
//                 >
//                   {intl.formatMessage({
//                     id: 'NAV.BUTTON.LOGOUT',
//                   })}
//                 </Button>
//               </>
//             ) : (
//               <>
//                 <Button color="inherit" href="/login" onClick={() => handleItemClick('login')}
//                   active={activeItem === 'login'}
//                   className={activeItem === 'login' ? 'active-nav-link' : ''}>
//                   {intl.formatMessage({
//                     id: 'NAV.BUTTON.LOGIN',
//                   })}
//                 </Button>
//                 <Button color="inherit" href="/register" onClick={() => handleItemClick('register')}
//                   active={activeItem === 'register'}
//                   className={activeItem === 'register' ? 'active-nav-link' : ''} >
//                   {intl.formatMessage({
//                     id: 'NAV.BUTTON.SIGN_UP',
//                   })}
//                 </Button>
//               </>
//             )}
//       </List>
//     </div>
//   </Drawer>
//   </div>
// </>
//       ) : (
//         <Box sx={{ display: 'flex' , justifyContent:"center" , width:"100%"}}>

//         <AppBar >
  
            
//         <Container  open={isOpen} style={{justifyContent:"space-evenly", padding:"1rem 0rem" }}>
                  
//             <Button
//               href="/homepage"
//               onClick={() => {
//                 handleItemClick('homepage');
//                 setDrawerOpen(true);
//               }}
//               className={activeItem === 'homepage' ? 'active-nav-link' : ''}
//                    color="inherit"
//             >
//                FinancialPredictionAI
             
//             </Button>
//             {auth.token ? (
//               <>
//                 <Button
//                   color="inherit"
//                   onClick={() => handleItemClick('analysis')}
//                   active={activeItem === 'analysis'}
//                   className={activeItem === 'analysis' ? 'active-nav-link' : ''}
//                 >
//                   {intl.formatMessage({
//                     id: 'NAV.BUTTON.ANALYSIS',
//                   })}
//                 </Button>
//                 <SwipeableDrawer
//                   anchor="left"
//                   open={drawerOpen}
//                   onClose={() => setDrawerOpen(false)}
//                   onOpen={() => setDrawerOpen(true)}
//                 >
//                   {list}
//                 </SwipeableDrawer>
//               </>
//             ) : (
//               <Button
//                 color="inherit"
//                 href="/analysis"
//                 className={activeItem === 'analysis' ? 'active-nav-link' : ''}
//                 active={activeItem === 'analysis'}
//                 onClick={() => handleItemClick('analysis')}
//               >
//                 {intl.formatMessage({
//                   id: 'NAV.BUTTON.ANALYSIS',
//                 })}
//               </Button>
//             )}
//             {auth.token ? (
//               <Button
//                 color="inherit"
//                 onClick={() => handleItemClick('loan')}
//                 active={activeItem === 'loan'}
//                 className={activeItem === 'loan' ? 'active-nav-link' : ''}
//                 href="/loan"
//               >
//                 {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}
//               </Button>
//             ) : (
//               <Button color="inherit" href="/get-loan" onClick={() => handleItemClick('get-loan')} active={activeItem === 'get-loan'}
//                 className={activeItem === 'get-loan' ? 'active-nav-link' : ''}>
//                 {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}
//               </Button>
//             )}
//             {auth.token ? (
//               <Button
//                 color="inherit"
//                 onClick={() => handleItemClick('equity')}
//                 active={activeItem === 'equity'}
//                 className={activeItem === 'equity' ? 'active-nav-link' : ''}
//                 href="/equity"
//               >
//                 {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
//               </Button>
//             ) : (
//               <Button color="inherit" onClick={() => handleItemClick('get-equity')}
//                 active={activeItem === 'get-equity'}
//                 className={activeItem === 'get-equity' ? 'active-nav-link' : ''} href="/get-equity">
//                 {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
//               </Button>
//             )}
//             {auth.token ? (
//               <>
//                 <Button color="inherit"
//                   onClick={() => handleItemClick('myfirm')}
//                   active={activeItem === 'myfirm'}
//                   className={activeItem === 'myfirm' ? 'active-nav-link' : ''}
//                   href="/myfirm"
//                 >
//                   {intl.formatMessage({
//                     id: 'NAV.BUTTON.MY_FIRM',
//                   })}
//                 </Button>
//                 <Button color="inherit"
//                   onClick={() => handleItemClick('myfirm-update')}
//                   active={activeItem === 'myfirm-update'}
//                   className={activeItem === 'myfirm-update' ? 'active-nav-link' : ''}
//                   href="/myfirm-update"
//                 >
//                   {intl.formatMessage({ id: 'SIDEBAR_ROUTES.ACCOUNT' })}
//                 </Button>
//                 <Button color="inherit"
//                   onClick={() =>
//                     dispatch(
//                       logOut(),
//                       navigate('/homepage'),
//                       toast.info('successfully logged out')
//                     )
//                   }
//                 >
//                   {intl.formatMessage({
//                     id: 'NAV.BUTTON.LOGOUT',
//                   })}
//                 </Button>
//               </>
//             ) : (
//               <>
//                 <Button color="inherit" href="/login" onClick={() => handleItemClick('login')}
//                   active={activeItem === 'login'}
//                   className={activeItem === 'login' ? 'active-nav-link' : ''}>
//                   {intl.formatMessage({
//                     id: 'NAV.BUTTON.LOGIN',
//                   })}
//                 </Button>
//                 <Button color="inherit" href="/register" onClick={() => handleItemClick('register')}
//                   active={activeItem === 'register'}
//                   className={activeItem === 'register' ? 'active-nav-link' : ''} >
//                   {intl.formatMessage({
//                     id: 'NAV.BUTTON.SIGN_UP',
//                   })}
//                 </Button>
//               </>
//             )}
//       <CountryFlag />
//       </Container>
//         </AppBar>
//       </Box>
//       )}
   
//     </>
//   );
// };

// export default Header;

import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import CountryFlag from 'components/CountryFlag';
import { logOut } from 'redux/actions/auth';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import styles from '.././../assets/styles/components/accordion/accordion.module.scss';
import storeConstants from 'redux/constants/actionTypes';
import { firmDelete, getFirmList } from 'redux/ApiCalls';
import AppBar from '@mui/material/AppBar';

import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';


const Header = () => {
  const intl = useIntl();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { firms } = useSelector((state) => state.getFirmList);
  

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(
    localStorage.getItem('activeItem') || ''
  );

  useEffect(() => {
    localStorage.setItem('activeItem', activeItem);
  }, []);

  useEffect(() => {
    getFirmList(auth.token)
      .then((res) => {
        dispatch({
          type: storeConstants.GET_FIRM_LIST,
          payload: res.data.results,
        });
      })
      .catch((e) => console.log(e));
  }, []);

  function changeLocation(placeToGo) {
    navigate(placeToGo, { replace: true });
    window.location.reload();
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };


  const [isOpens, setIsOpens] = useState(false);

  const toggleDrawers = (open) => (event) => {
    setIsOpens(open);
  };

  const list = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {firms.map((firm) => (
          <ListItem key={firm} disablePadding>
            <div className={styles.link_and_delete}>
              <Link
                className={styles.link_and_delete_link}
                to={`/analysis/${firm.slug}`}
              >
                <Button
                  className={styles.link_and_delete_link_button}
                  variant="contained"
                  onClick={() => {
                    dispatch({
                      type: storeConstants.TABS_ROUTER,
                      payload: true,
                    });
                  }}
                  size="small"
                >
                  <ListItemText primary={firm.name} />
                </Button>
              </Link>
              <div className={styles.link_and_delete_delete}>
                <Button
                  className={styles.link_and_delete_delete_button}
                  onClick={() => {
                    firmDelete(auth.token, firm.slug).then(() => {
                      toast.success(`${firm.name} deleted`);
                      getFirmList(auth.token)
                        .then((res) => {
                          dispatch({
                            type: storeConstants.GET_FIRM_LIST,
                            payload: res.data.results,
                          });
                          changeLocation('/analysis');
                        })
                        .catch((e) => console.log(e));
                    });
                  }}
                  size="small"
                  variant="outlined"
                  color="error"
                >
                  <i
                    style={{ fontSize: '20px' }}
                    className="fa fa-trash"
                    aria-hidden="true"
                  ></i>
                </Button>
              </div>
            </div>
          </ListItem>
        ))}
      </List>
      <Link
        id={styles.accordion_main_container_content_add_link}
        to="/analysis"
        onClick={() => changeLocation('/analysis')}
      >
        <Button
          type="submit"
          fullWidth
          size="small"
          variant="contained"
          id={styles.accordion_main_container_content_add_button}
        >
          {intl.formatMessage({
            id: 'FIRMACCORDION.BUTTON_CREATE',
          })}
          <i className="fa fa-plus" aria-hidden="true"></i>
        </Button>
      </Link>
    </Box>
  );

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);

    const handler = (e) => setIsMobile(e.matches);
    mediaQuery.addListener(handler);

    return () => mediaQuery.removeListener(handler);
  }, []);
  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
    localStorage.setItem('activeItem', itemName);
  };
  return (
    <>
  {isMobile ? (
  <>
  <div style={{width:"100%",display:"flex",justifyContent:"space-between"}}>


  <Button
              href="/homepage"
              onClick={() => {
                handleItemClick('homepage');
                setDrawerOpen(true);
              }}
              className={activeItem === 'homepage' ? 'active-nav-link' : ''}
              style={{color:"black"}}
            >
               FinancialPredictionAI
             
   </Button>
   <div style={{display:"flex"}}>
   <IconButton onClick={toggleDrawers(true)}>
    <MenuIcon />
  </IconButton>
  <CountryFlag />
  </div>

  <Drawer anchor="top" open={isOpens} onClose={toggleDrawers(false)} >
    
    <div
      onClick={toggleDrawers(false)}
      onKeyDown={toggleDrawers(false)}
    >
      <List style={{width:"100%",display:"flex",justifyContent:"space-between", flexDirection:"column"}}>
      {auth.token ? (
              <>
                <Button
                  color="inherit"
                  onClick={() => handleItemClick('analysis')}
                  active={activeItem === 'analysis'}
                  className={activeItem === 'analysis' ? 'active-nav-link' : ''}
                >
                  {intl.formatMessage({
                    id: 'NAV.BUTTON.ANALYSIS',
                  })}
                </Button>
                <SwipeableDrawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={() => setDrawerOpen(false)}
                  onOpen={() => setDrawerOpen(true)}
                >
                  {list}
                </SwipeableDrawer>
              </>
            ) : (
              <Button
                color="inherit"
                href="/analysis"
                className={activeItem === 'analysis' ? 'active-nav-link' : ''}
                active={activeItem === 'analysis'}
                onClick={() => handleItemClick('analysis')}
              >
                {intl.formatMessage({
                  id: 'NAV.BUTTON.ANALYSIS',
                })}
              </Button>
            )}
            {auth.token ? (
              <Button
                color="inherit"
                onClick={() => handleItemClick('loan')}
                active={activeItem === 'loan'}
                className={activeItem === 'loan' ? 'active-nav-link' : ''}
                href="/loan"
              >
                {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}
              </Button>
            ) : (
              <Button color="inherit" href="/get-loan" onClick={() => handleItemClick('get-loan')} active={activeItem === 'get-loan'}
                className={activeItem === 'get-loan' ? 'active-nav-link' : ''}>
                {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}
              </Button>
            )}
            {auth.token ? (
              <Button
                color="inherit"
                onClick={() => handleItemClick('equity')}
                active={activeItem === 'equity'}
                className={activeItem === 'equity' ? 'active-nav-link' : ''}
                href="/equity"
              >
                {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
              </Button>
            ) : (
              <Button color="inherit" onClick={() => handleItemClick('get-equity')}
                active={activeItem === 'get-equity'}
                className={activeItem === 'get-equity' ? 'active-nav-link' : ''} href="/get-equity">
                {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
              </Button>
            )}
            {auth.token ? (
              <>
                <Button color="inherit"
                  onClick={() => handleItemClick('myfirm')}
                  active={activeItem === 'myfirm'}
                  className={activeItem === 'myfirm' ? 'active-nav-link' : ''}
                  href="/myfirm"
                >
                  {intl.formatMessage({
                    id: 'NAV.BUTTON.MY_FIRM',
                  })}
                </Button>
                <Button color="inherit"
                  onClick={() => handleItemClick('myfirm-update')}
                  active={activeItem === 'myfirm-update'}
                  className={activeItem === 'myfirm-update' ? 'active-nav-link' : ''}
                  href="/myfirm-update"
                >
                  {intl.formatMessage({ id: 'SIDEBAR_ROUTES.ACCOUNT' })}
                </Button>
                <Button color="inherit"
                  onClick={() =>
                    dispatch(
                      logOut(),
                      navigate('/homepage'),
                      toast.info('successfully logged out')
                    )
                  }
                >
                  {intl.formatMessage({
                    id: 'NAV.BUTTON.LOGOUT',
                  })}
                </Button>
              </>
            ) : (
              <>
                <Button color="inherit" href="/login" onClick={() => handleItemClick('login')}
                  active={activeItem === 'login'}
                  className={activeItem === 'login' ? 'active-nav-link' : ''}>
                  {intl.formatMessage({
                    id: 'NAV.BUTTON.LOGIN',
                  })}
                </Button>
                <Button color="inherit" href="/register" onClick={() => handleItemClick('register')}
                  active={activeItem === 'register'}
                  className={activeItem === 'register' ? 'active-nav-link' : ''} >
                  {intl.formatMessage({
                    id: 'NAV.BUTTON.SIGN_UP',
                  })}
                </Button>
              </>
            )}
      </List>
    </div>
  </Drawer>
  </div>
</>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
  
            
        <Container style={{justifyContent:"space-evenly", padding:"1rem 0rem"}}>
                  
            <Button
              href="/homepage"
              onClick={() => {
                handleItemClick('homepage');
                setDrawerOpen(true);
              }}
              className={activeItem === 'homepage' ? 'active-nav-link' : ''}
                   color="inherit"
            >
               FinancialPredictionAI
             
            </Button>
            {auth.token ? (
              <>
                <Button
                  color="inherit"
                  onClick={() => handleItemClick('analysis')}
                  active={activeItem === 'analysis'}
                  className={activeItem === 'analysis' ? 'active-nav-link' : ''}
                >
                  {intl.formatMessage({
                    id: 'NAV.BUTTON.ANALYSIS',
                  })}
                </Button>
                <SwipeableDrawer
                  anchor="left"
                  open={drawerOpen}
                  onClose={() => setDrawerOpen(false)}
                  onOpen={() => setDrawerOpen(true)}
                >
                  {list}
                </SwipeableDrawer>
              </>
            ) : (
              <Button
                color="inherit"
                href="/analysis"
                className={activeItem === 'analysis' ? 'active-nav-link' : ''}
                active={activeItem === 'analysis'}
                onClick={() => handleItemClick('analysis')}
              >
                {intl.formatMessage({
                  id: 'NAV.BUTTON.ANALYSIS',
                })}
              </Button>
            )}
            {auth.token ? (
              <Button
                color="inherit"
                onClick={() => handleItemClick('loan')}
                active={activeItem === 'loan'}
                className={activeItem === 'loan' ? 'active-nav-link' : ''}
                href="/loan"
              >
                {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}
              </Button>
            ) : (
              <Button color="inherit" href="/get-loan" onClick={() => handleItemClick('get-loan')} active={activeItem === 'get-loan'}
                className={activeItem === 'get-loan' ? 'active-nav-link' : ''}>
                {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}
              </Button>
            )}
            {auth.token ? (
              <Button
                color="inherit"
                onClick={() => handleItemClick('equity')}
                active={activeItem === 'equity'}
                className={activeItem === 'equity' ? 'active-nav-link' : ''}
                href="/equity"
              >
                {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
              </Button>
            ) : (
              <Button color="inherit" onClick={() => handleItemClick('get-equity')}
                active={activeItem === 'get-equity'}
                className={activeItem === 'get-equity' ? 'active-nav-link' : ''} href="/get-equity">
                {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
              </Button>
            )}
            {auth.token ? (
              <>
                <Button color="inherit"
                  onClick={() => handleItemClick('myfirm')}
                  active={activeItem === 'myfirm'}
                  className={activeItem === 'myfirm' ? 'active-nav-link' : ''}
                  href="/myfirm"
                >
                  {intl.formatMessage({
                    id: 'NAV.BUTTON.MY_FIRM',
                  })}
                </Button>
                <Button color="inherit"
                  onClick={() => handleItemClick('myfirm-update')}
                  active={activeItem === 'myfirm-update'}
                  className={activeItem === 'myfirm-update' ? 'active-nav-link' : ''}
                  href="/myfirm-update"
                >
                  {intl.formatMessage({ id: 'SIDEBAR_ROUTES.ACCOUNT' })}
                </Button>
                <Button color="inherit"
                  onClick={() =>
                    dispatch(
                      logOut(),
                      navigate('/homepage'),
                      toast.info('successfully logged out')
                    )
                  }
                >
                  {intl.formatMessage({
                    id: 'NAV.BUTTON.LOGOUT',
                  })}
                </Button>
              </>
            ) : (
              <>
                <Button color="inherit" href="/login" onClick={() => handleItemClick('login')}
                  active={activeItem === 'login'}
                  className={activeItem === 'login' ? 'active-nav-link' : ''}>
                  {intl.formatMessage({
                    id: 'NAV.BUTTON.LOGIN',
                  })}
                </Button>
                <Button color="inherit" href="/register" onClick={() => handleItemClick('register')}
                  active={activeItem === 'register'}
                  className={activeItem === 'register' ? 'active-nav-link' : ''} >
                  {intl.formatMessage({
                    id: 'NAV.BUTTON.SIGN_UP',
                  })}
                </Button>
              </>
            )}
      <CountryFlag />
      </Container>
        </AppBar>
      </Box>
      )}
   
    </>
  );
};

export default Header;

