import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as analysisDataActions from 'redux/actions/analysisDataActions'
import { useIntl } from 'react-intl'
import * as ReactBootStrap from 'react-bootstrap'
import {
  getLastCustomScenario,
  analysis,
  getCustomScenarioList,
  getCustomScenarioDataList,
  customScenarioDataDetail,
  analysisList,
} from 'redux/ApiCalls'
import { spinnerStyle } from 'components/LayoutStyles'
import Spinner from 'components/Spinner'
import { useParams } from 'react-router-dom'
import storeConstants from 'redux/constants/actionTypes'
import ReactTooltip from 'react-tooltip'

const CustomScenario = (props) => {
  const intl = useIntl()
  const params = useParams()
  const dispatch = useDispatch()
  const [lastKeyPress, setLastKeyPress] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { token } = useSelector((state) => state.auth)

  const [isLastCustomScenario, setIsLastCustomScenario] = useState(false)

  const {
    firmBackground,
    financial_statements,
    fx_position,
    maturity_short_term,
    business_plan,
    analysisResult,
    firmSlug,
    initial_custom_scenario,
  } = useSelector((state) => state.analysisDataReducer)
  const { scenarios, enable_custom_scenario } = analysisResult
  const tourState = useSelector((state) => state.joyRide)

  const trueOrFalse = (() => {
    if (firmBackground.industry === 'Finance & Insurance') {
      return false
    }
    return true
  })()

  const calculateAnalysis = (isCustomScenario) => {
    setIsLoading(true)
    if (analysisResult.enable_custom_scenario !== isCustomScenario) {
      isCustomScenario
        ? dispatch(analysisDataActions.enableCustomScenario())
        : dispatch(analysisDataActions.disableCustomScenario())
    }
    const postData = {
      analysis_data: {
        financial: {},
        fx: {},
        maturity: {},
        business_plan: {},
        scenario: {},
      },
      firm_background: firmBackground,
      is_analysis_real: trueOrFalse,
      enable_custom_scenario: isCustomScenario,
    }

    financial_statements.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key].push(newValue)
        })
      }
    })

    fx_position.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key].push(newValue)
        })
      }
    })

    maturity_short_term.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key].push(newValue)
        })
      }
    })

    business_plan.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key].push(newValue)
        })
      }
    })

    if (analysisResult?.scenarios) {
      console.log('i')
      analysisResult.scenarios.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    } else {
      console.log('in')
      initial_custom_scenario.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    }

    analysis(token, postData)
      .then(({ data }) => {
        dispatch(analysisDataActions.fetchRealAnalysis(data))
        setIsLoading(false)
      })
      .catch((err) => err)
  }
  // const handleInputChange = (value, key, index) => {
  //   if (isLoading) {
  //     return
  //   }
  //   const newCustomScenarioData = [...scenarios]

  //   const findValue = newCustomScenarioData[index].values.find(
  //     (item) => item.key === key
  //   )

  //   findValue.value = value
  //   dispatch(analysisDataActions.customScenario(newCustomScenarioData))
  //   setLastKeyPress(newCustomScenarioData)
  //   const numberRegex = /[0-9]/g

  //   let newValue = value.replace(/\./g, '')

  //   if (value === '' || newValue === '') {
  //     newValue = '0'
  //   }

  //   if (newValue.match(numberRegex)?.length === newValue.length) {
  //     const newCustomScenarioData = [...scenarios]

  //     const findValue = newCustomScenarioData[index].values.find(
  //       (item) => item.key === key
  //     )

  //     findValue.value = Number(newValue).toLocaleString('en-US')
  //     dispatch(analysisDataActions.customScenario(newCustomScenarioData))
  //     setLastKeyPress(newCustomScenarioData)
  //     setIsLastCustomScenario(true)
  //     dispatch({
  //       type: storeConstants.REMOVE_OR_GET_LAST,
  //       payload: true,
  //     })
  //   }

  // }

  const handleInputChange = (value, key, index) => {
    if (isLoading) {
      return
    }

    const newCustomScenarioData = [...scenarios]
    const findValue = newCustomScenarioData[index].values.find(
      (item) => item.key === key
    )

    findValue.value = value
    dispatch(analysisDataActions.customScenario(newCustomScenarioData))
    setLastKeyPress(newCustomScenarioData)

    const numberRegex = /^-?\d*\.?\d+$/ // Allows negative numbers and decimals
    let newValue = value.replace(/\./g, '')

    if (value === '' || newValue === '') {
      newValue = '0'
    }

    if (newValue.match(numberRegex)?.length === newValue.length) {
      const newCustomScenarioData = [...scenarios]
      const findValue = newCustomScenarioData[index].values.find(
        (item) => item.key === key
      )

      findValue.value = Number(newValue).toLocaleString('en-US')
      dispatch(analysisDataActions.customScenario(newCustomScenarioData))
      setLastKeyPress(newCustomScenarioData)
      setIsLastCustomScenario(true)

      dispatch({
        type: storeConstants.REMOVE_OR_GET_LAST,
        payload: true,
      })
    }
  }

  useEffect(() => {
    if (isLastCustomScenario) {
      const timeoutId = setTimeout(() => {
        dispatch(analysisDataActions.enableCustomScenario())
        calculateAnalysis(true)
      }, 2000)
      return () => clearTimeout(timeoutId)
    }
  }, [isLastCustomScenario])

  useEffect(() => {
    if (lastKeyPress) {
      const timeoutId = setTimeout(() => {
        calculateAnalysis(true)
        setIsLoading(true)
      }, 2000)
      return () => clearTimeout(timeoutId)
    }
  }, [lastKeyPress])

  useEffect(() => {
    console.log(enable_custom_scenario, 'enable_custom_scenario')
    if (enable_custom_scenario) {
      const timeoutId = setTimeout(() => {
        calculateAnalysis(true)
        setIsLoading(true)
      }, 2000)
      return () => clearTimeout(timeoutId)
    }
  }, [enable_custom_scenario])

  const table = (
    <div className="financial-main">
      <table className="financial-main-table">
        <thead>
          <tr>
            <th className="fixed-row">
              {intl.formatMessage({
                id: 'CUSTOM_SCENARIO.TABLE_TITLE',
              })}
            </th>
            <th>
              <th
                className="common-row1-custom"
                style={{ display: 'flex', justifyContent: 'space-around' }}
              >
                {scenarios?.map((item, index) => (
                  <th key={index} scope="col">
                    {new Date(item.year).toLocaleString('en-US', {
                      year: 'numeric',
                    }) +
                      '-' +
                      new Date(item.year).toLocaleString('en-US', {
                        month: 'short',
                      })}
                  </th>
                ))}
              </th>
            </th>
          </tr>
        </thead>
        <tbody>
          {scenarios
            ? scenarios[0]?.values?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td
                    className="financial-main-table-fixed-row"
                    data-tip={
                      row.label === 'Export Market GDP'
                        ? intl.formatMessage({
                            id: 'tooltip_WGDP',
                          })
                        : row.label === 'Domestic Market GDP'
                        ? intl.formatMessage({
                            id: 'tooltip_GDP',
                          })
                        : row.label === 'FX Rate (LCY/USD)'
                        ? intl.formatMessage({
                            id: 'tooltip_FX',
                          })
                        : row.label === 'Lending Rate on Local Curency Debts'
                        ? intl.formatMessage({
                            id: 'tooltip_LR',
                          })
                        : row.label === 'Lending Rate on Foreign Curency Debts'
                        ? intl.formatMessage({
                            id: 'tooltip_US_LR',
                          })
                        : row.label === 'Inflation'
                        ? intl.formatMessage({
                            id: 'tooltip_INF',
                          })
                        : row.label === 'Export Market Inflation'
                        ? intl.formatMessage({
                            id: 'tooltip_US_INF',
                          })
                        : row.label ===
                          'Rate of Return on Domestic Equity Investments'
                        ? intl.formatMessage({
                            id: 'tooltip_ER',
                          })
                        : row.label ===
                          'Rate of Return on Foreign Equity Investments'
                        ? intl.formatMessage({
                            id: 'tooltip_OIL',
                          })
                        : row.label ===
                          'Rate of Return on Foreign Equity Investments'
                        ? intl.formatMessage({
                            id: 'tooltip_OIL',
                          })
                        : ''
                    }
                  >
                    <ReactTooltip />
                    {intl.formatMessage({
                      id: row.key,
                    })}
                  </td>
                  <td>
                    {scenarios.map((yearData, columnIndex) => (
                      <>
                        <td key={columnIndex}>
                          {/* <input
                        type="text"
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            row.key,
                            columnIndex
                          )
                        }
                        defaultValue="0"
                        step={'.01'}
                        value={yearData.values[rowIndex].value.toLocaleString('en-US')}
                        className="financial-main-table-input"
                      /> */}
                          <input
                            type="text"
                            onChange={(e) =>
                              handleInputChange(
                                e.target.value,
                                row.key,
                                columnIndex
                              )
                            }
                            value={yearData.values[
                              rowIndex
                            ].value.toLocaleString('en-US')}
                            className="financial-main-table-input"
                            pattern="^-?\d*\.?\d+" // Allows negative numbers and decimals
                          />
                        </td>
                      </>
                    ))}
                  </td>
                </tr>
              ))
            : ''}
        </tbody>
      </table>
    </div>
  )

  useEffect(() => {
    tourState.indexTab3 === false && window.scrollTo(0, 0)
  }, [tourState.indexTab3])

  return (
    <div>
      {isLoading ? <Spinner /> : ''}
      <div className="analysis-table-header-container">
        <div className="analysis-table-header-container-sub">
          <div className="analysis-table-header-container-table">{table}</div>
        </div>
      </div>
    </div>
  )
}

export default CustomScenario
