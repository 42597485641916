import React from 'react'
import { useDispatch } from 'react-redux';
import {
    BrowserRouter as Router,
    useLocation,
    useNavigate
} from "react-router-dom";
import { resetPassworConfirm } from 'redux/ApiCalls';
import storeConstants from 'redux/constants/actionTypes';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
const theme = createTheme();

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PasswordResetConfirm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const intl = useIntl()
    const query = useQuery();

    const resetToken = query.get('token');
    const uidb64 = query.get('uidb64');

    const onSubmit = e => {
        e.preventDefault();
        navigate('/password-reset-complete');

        dispatch(
            {
                type: storeConstants.PASSWORD_RESET_TOKEN_AND_UIDB64,
                payload: {
                    resetToken,
                    uidb64
                }
            })
        resetPassworConfirm(uidb64, resetToken)
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
    };


    return (
        <>{resetToken && uidb64 ?


            <ThemeProvider theme={theme}>
                <div className="main-login">
                    <Container component="main" maxWidth="xs">
                        <a
                            className="button-equlion"
                            style={{
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textDecoration: 'none',
                            }}
                        >
                            <span style={{ color: 'white' }}>Equ</span>
                            <span className="header-menu-logo-lion" style={{ color: '#f73681' }}>
                                <span style={{ fontSize: '40px' }}>l</span>ion
                            </span>
                        </a>
                        <CssBaseline />
                        <Box
                            sx={{
                                marginTop: 8,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, background: "Transparent" }}>
                            </Avatar>
                            <Box className="auth_form" component="form" noValidate sx={{ mt: 3 }} onSubmit={e => onSubmit(e)}>
                                <svg className="signup-sides">
                                    <line className="top-right first" x1="50%" x2="100%" y1="0" y2="0" />
                                    <line className="top-left first" x1="50%" x2="0" y1="0" y2="0" />
                                    <line className="right second" x1="100%" x2="100%" y1="0%" y2="100%" />
                                    <line className="left second" x1="0" x2="0" y1="0" y2="100%" />
                                    <line className="bottom-left third" x1="0" x2="50%" y1="100%" y2="100%" />
                                    <line className="bottom-right third" x1="100%" x2="50%" y1="100%" y2="100%" />
                                </svg>

                                <Button
                                    type="submit"
                                    fullWidth
                                    size="small"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    {intl.formatMessage({
                                        id: 'RESET_PASSWORD.BUTTON',
                                    })}

                                </Button>

                                <Grid container justifyContent="flex-start">
                                    <Grid item>
                                        <Typography  >
                                            {
                                                intl.formatMessage({
                                                    id: "RESET_PASSWORD_CONFIRM.TEXT"
                                                })
                                            }
                                        </Typography >
                                    </Grid>
                                </Grid>

                            </Box>
                        </Box>
                    </Container>
                </div>
            </ThemeProvider>
            : ""
        }
        </>
    )
}

export default PasswordResetConfirm;