import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'

const PrivateRoute = ({ auth, children }) => {
  if (!auth.token) {
    return <Navigate to="/homepage" />
  } else {
    return children
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(PrivateRoute)
