import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Bar } from 'react-chartjs-2'

const indicatorsBackgroundColor = [
  'rgba(255, 99, 132, 0.6)',
  'rgba(54, 162, 235, 0.6)',
  'rgba(255, 206, 86, 0.6)',
  'rgba(75, 192, 192, 0.6)',
  'rgba(153, 102, 255, 0.6)',
  'rgba(255, 159, 64, 0.6)',
  'rgba(255, 99, 132, 0.6)',
]

const BaselineAndCustomChart = () => {
  const [chartIndicatorsDatas, setChartIndicatorsDatas] = useState({})

  const { analysisResult, firmBackground } = useSelector(
    (state) => state.analysisDataReducer
  )

  const { enable_custom_scenario } = analysisResult

  const labels = []

  // analysisResult?.financial_statements?.map((datas) => {
  //   labels.push(datas?.year)
  // })

  const indicatorsTables = (keyArrays) => {
    let newChartData = {}

    keyArrays.map((key) => {
      const dataSet = []

      const customDataSet = {
        backgroundColor: indicatorsBackgroundColor,
        fill: false,
        label: 'Custom Scenario',
        title: 'test',
        chartType: 'line',
        data: [],
      }

      if (enable_custom_scenario) {
        analysisResult?.custom_financial_statements?.map((datas) => {
          const totalAsetValue = datas.values.find((item) => item.key === key)
          customDataSet.data.push(totalAsetValue.value)
        })
        dataSet.push(customDataSet)
      }

      newChartData[key] = { labels, datasets: dataSet }
    })
    setChartIndicatorsDatas(newChartData)
  }

  useEffect(() => {
    indicatorsTables([
      'Capital Adequacy Ratio(CAR)',
      'Liquidity Ratio',
      'Growth Rate Of Loans',
      'NPL Ratio',
      'Specific Reserve Ratio',
      'Loans to Deposits',
      'Leverage',
      'Net Interest Margin',
      'Return on Assets(ROA)',
      'Return on Equity(ROE)',
    ])
  }, [])

  return (
    <div>
      {analysisResult?.financial_statements &&
      chartIndicatorsDatas['Capital Adequacy Ratio(CAR)'] ? (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-6">
              <h3>
                {intl.formatMessage({
                  id: 'FINANCIAL_CHART.ROE',
                })}
              </h3>
              <Bar data={chartIndicatorsDatas['Return on Equity(ROE)']} />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default BaselineAndCustomChart
