import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetPasswordComplete } from 'redux/ApiCalls';
import { toast } from 'react-toastify'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
const theme = createTheme();


const PasswordResetComplete = () => {
    const navigate = useNavigate();
    const intl = useIntl()

    const [formData, setFormData] = useState({
        password: ''
    });

    const { resetToken, uidb64 } = useSelector((state) => state.auth.resetTokenAnduidb64)

    const { password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        resetPasswordComplete(uidb64, resetToken, password)
            .then((res) => res.data.success === true ? (toast.success("Password reset success"), navigate("/login")) : "")
            .catch((err) => toast.error("Authentication Failed"))
    };


    return (
        <>{(resetToken !== null) && (uidb64 !== null) ?
            <>
                <ThemeProvider theme={theme}>
                    <div className="main-login">
                        <Container component="main" maxWidth="xs">
                            <a
                                className="button-equlion"
                                style={{
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textDecoration: 'none',
                                }}
                            >
                                <span style={{ color: 'white' }}>Equ</span>
                                <span className="header-menu-logo-lion" style={{ color: '#f73681' }}>
                                    <span style={{ fontSize: '40px' }}>l</span>ion
                                </span>
                            </a>
                            <CssBaseline />
                            <Box
                                sx={{
                                    marginTop: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar sx={{ m: 1, background: "Transparent" }}>
                                </Avatar>
                                <Box className="auth_form" component="form" noValidate sx={{ mt: 3 }} onSubmit={e => onSubmit(e)}>
                                    <svg className="signup-sides">
                                        <line className="top-right first" x1="50%" x2="100%" y1="0" y2="0" />
                                        <line className="top-left first" x1="50%" x2="0" y1="0" y2="0" />
                                        <line className="right second" x1="100%" x2="100%" y1="0%" y2="100%" />
                                        <line className="left second" x1="0" x2="0" y1="0" y2="100%" />
                                        <line className="bottom-left third" x1="0" x2="50%" y1="100%" y2="100%" />
                                        <line className="bottom-right third" x1="100%" x2="50%" y1="100%" y2="100%" />
                                    </svg>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            size="small"
                                            name="password"
                                            onChange={e => onChange(e)}
                                            label={intl.formatMessage({
                                                id: 'RESET_PASSWORD_COMPLETE.TEXT',
                                            })}
                                            type="password"
                                            id="password"
                                            autoComplete="current-password"
                                        />
                                    </Grid>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        size="small"
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        minLength="8"
                                    >
                                        {intl.formatMessage({
                                            id: 'RESET_PASSWORD_COMPLETE.BUTTON',
                                        })}
                                    </Button>


                                </Box>
                            </Box>
                        </Container>
                    </div>
                </ThemeProvider>
            </>
            : ""}

        </>
    )
}

export default PasswordResetComplete