// import {
//   customScenarioCreate,
//   financialAnalysisCreate,
//   financialBusinessCreate,
//   financialFxCreate,
//   financialMaturityCreate,
//   realAnalysisCreate,
//   realBusinessCreate,
//   realFxCreate,
//   realMaturityCreate,
//   analysisCreate,
//   customScenarioDataCreate
// } from 'redux/ApiCalls'

// const saveAnalysis = (
//   token,
//   firmBackground,
//   financial_statements,
//   fx_position,
//   maturity_short_term,
//   business_plan,
//   custom,
//   firmSlug,
//   customScenarioData,
// ) => {

//   return new Promise((resolve, reject) => {
//     if (token) {
//       let newFinancialStatements = [...financial_statements]
//       newFinancialStatements.map((yearData) => {
//         yearData.values.map((data, index) => {
//           if (typeof data.value === 'string') {
//             yearData.values[index].value = Number(data.value.replace(/,/g, ''))
//           }
//         })
//       })

//       let newFx = [...fx_position]
//       newFx.map((yearData) => {
//         yearData.values.map((data, index) => {
//           if (typeof data.value === 'string') {
//             yearData.values[index].value = Number(data.value.replace(/,/g, ''))
//           }
//         })
//       })

//       let newMaturity = [...maturity_short_term]
//       newMaturity.map((yearData) => {
//         yearData.values.map((data, index) => {
//           if (typeof data.value === 'string') {
//             yearData.values[index].value = Number(data.value.replace(/,/g, ''))
//           }
//         })
//       })

//       let newBusiness = [...business_plan]
//       newBusiness.map((yearData) => {
//         yearData.values.map((data, index) => {
//           if (typeof data.value === 'string') {
//             yearData.values[index].value = Number(data.value.replace(/,/g, ''))
//           }
//         })
//       })

//       analysisCreate(token, firmSlug, { name: "SaveAnalysis", is_default: 1 })
//         .then(({ data }) => {
//           let analysisId = data.id
//           if (firmBackground.industry === 'Finance & Insurance') {
//             Promise.all([
//               (financialAnalysisCreate(token, newFinancialStatements, analysisId), financialFxCreate(token, newFx, analysisId), financialMaturityCreate(token, newMaturity, analysisId)), financialBusinessCreate(token, newBusiness, analysisId),
//             ])
//               .then(() => {

//                 customScenarioCreate(token, custom, analysisId)
//                   .then(({ data }) => {
//                     let customId = data.id
//                     let analyzeId = data.analyze
//                     custom.map((yearData) => {
//                       let newCustom = {}
//                       yearData.values.map((data, index) => {
//                         newCustom[data.key] = data.value
//                       })
//                       newCustom.year_for_data = new Date(yearData.year).toISOString();
//                       newCustom.projection_frequency = firmBackground.projection_frequency;
//                       customScenarioDataCreate(token, newCustom, analyzeId, customId).catch(err => reject())
//                     })
//                   })
//                   .catch((err) => console.log(err))
//                 resolve()
//               })
//               .catch((err) =>
//                 reject({
//                   errorType: 'api',
//                   errorMessage: err
//                 })
//               )
//           } else {
//             Promise.all([
//               (realAnalysisCreate(token, newFinancialStatements, analysisId),
//                 realFxCreate(token, newFx, analysisId),
//                 realMaturityCreate(token, newMaturity, analysisId)), realBusinessCreate(token, newBusiness, analysisId),
//             ])
//               .then(() => {
//                 customScenarioCreate(token, custom, analysisId)
//                   .then(({ data }) => {
//                     let customId = data.id
//                     let analyzeId = data.analyze
//                     custom.map((yearData) => {
//                       let newCustom = {}
//                       yearData.values.map((data, index) => {
//                         newCustom[data.key] = data.value
//                       })
//                       newCustom.year_for_data = new Date(yearData.year).toISOString();
//                       newCustom.projection_frequency = firmBackground.projection_frequency;
//                       customScenarioDataCreate(token, newCustom, analyzeId, customId)
//                         .catch(err => reject())
//                     })
//                   })
//                   .catch((err) => console.log(err))
//                 resolve()
//               })
//               .catch((err) =>
//                 reject({
//                   errorType: 'api',
//                   errorMessage: err
//                 })
//               )
//           }
//         })
//         .catch((e) => console.log(e))
//     } else {
//       reject({
//         errorType: 'noToken'
//       })
//     }
//   })
// }

// export default saveAnalysis

import {
  customScenarioCreate,
  financialAnalysisCreate,
  financialBusinessCreate,
  financialFxCreate,
  financialMaturityCreate,
  realAnalysisCreate,
  realBusinessCreate,
  realFxCreate,
  realMaturityCreate,
  analysisCreate,
  customScenarioDataCreate,
  customScenarioDataUpdate,
} from 'redux/ApiCalls'

const saveAnalysis = (
  token,
  firmBackground,
  financial_statements,
  fx_position,
  maturity_short_term,
  business_plan,
  custom,
  firmSlug,
  activeTab,
  analysisIdNoYes
) => {
  return new Promise((resolve, reject) => {
    if (token) {
      let newFinancialStatements = [...financial_statements]
      newFinancialStatements.map((yearData) => {
        yearData.values.map((data, index) => {
          if (typeof data.value === 'string') {
            yearData.values[index].value = Number(data.value.replace(/,/g, ''))
          }
        })
      })

      let newFx = [...fx_position]
      newFx.map((yearData) => {
        yearData.values.map((data, index) => {
          if (typeof data.value === 'string') {
            yearData.values[index].value = Number(data.value.replace(/,/g, ''))
          }
        })
      })

      let newMaturity = [...maturity_short_term]
      newMaturity.map((yearData) => {
        yearData.values.map((data, index) => {
          if (typeof data.value === 'string') {
            yearData.values[index].value = Number(data.value.replace(/,/g, ''))
          }
        })
      })

      let newBusiness = [...business_plan]
      newBusiness.map((yearData) => {
        yearData.values.map((data, index) => {
          if (typeof data.value === 'string') {
            yearData.values[index].value = Number(data.value.replace(/,/g, ''))
          }
        })
      })

      if (analysisIdNoYes) {
        if (firmBackground.industry === 'Finance & Insurance') {
          console.log('fin1')
          Promise.all([
            activeTab === 1 || activeTab === 2 || activeTab === 3
              ? (financialAnalysisCreate(
                  token,
                  newFinancialStatements,
                  analysisId
                ),
                financialFxCreate(token, newFx, analysisId),
                financialMaturityCreate(token, newMaturity, analysisId))
              : activeTab === 4
              ? financialBusinessCreate(token, newBusiness, analysisIdNoYes)
              : activeTab === 5
              ? customScenarioCreate(token, custom, analysisIdNoYes)
                  .then(({ data }) => {
                    let customId = data.id
                    let analyzeId = data.analyze
                    custom.map((yearData) => {
                      let newCustom = {}
                      yearData.values.map((data, index) => {
                        newCustom[data.key] = data.value
                      })
                      newCustom.year_for_data = new Date(
                        yearData.year
                      ).toISOString()
                      newCustom.projection_frequency =
                        firmBackground.projection_frequency
                      customScenarioDataCreate(
                        token,
                        newCustom,
                        analyzeId,
                        customId
                      ).catch((err) => reject())
                    })
                  })
                  .catch((err) => console.log(err))
              : [],
          ]).catch(
            (err) => console.log('res00'),
            reject({
              errorType: 'api',
              errorMessage: err,
            })
          )
        } else {
          Promise.all([
            activeTab === 1 || activeTab === 2 || activeTab === 3
              ? (realAnalysisCreate(
                  token,
                  newFinancialStatements,
                  analysisIdNoYes
                ),
                realFxCreate(token, newFx, analysisIdNoYes),
                realMaturityCreate(token, newMaturity, analysisIdNoYes))
              : activeTab === 4
              ? realBusinessCreate(token, newBusiness, analysisIdNoYes)
              : activeTab === 5
              ? customScenarioCreate(token, custom, analysisIdNoYes)
                  .then(({ data }) => {
                    let customId = data.id
                    let analyzeId = data.analyze
                    custom.map((yearData) => {
                      let newCustom = {}
                      yearData.values.map((data, index) => {
                        newCustom[data.key] = data.value
                      })
                      newCustom.year_for_data = new Date(
                        yearData.year
                      ).toISOString()
                      newCustom.projection_frequency =
                        firmBackground.projection_frequency
                      customScenarioDataCreate(
                        token,
                        newCustom,
                        analyzeId,
                        customId
                      ).catch((err) => reject())
                    })
                  })
                  .catch((err) => console.log(err))
              : [],
          ]).catch((err) =>
            reject({
              errorType: 'api',
              errorMessage: err,
            })
          )
        }
      } else {
        console.log(firmSlug, 332)
        analysisCreate(token, firmSlug)
          .then(({ data }) => {
            let analysisId = data.id
            if (firmBackground.industry === 'Finance & Insurance') {
              Promise.all([
                activeTab === 1 || activeTab === 2 || activeTab === 3
                  ? (financialAnalysisCreate(
                      token,
                      newFinancialStatements,
                      analysisId
                    ),
                    financialFxCreate(token, newFx, analysisId),
                    financialMaturityCreate(token, newMaturity, analysisId))
                  : financialBusinessCreate(token, newBusiness, analysisId),
                activeTab === 4
                  ? financialBusinessCreate(token, newBusiness, analysisId)
                  : [],
              ])
                .then(() => {
                  if (activeTab === 5) {
                    customScenarioCreate(token, custom, analysisId)
                      .then(({ data }) => {
                        let customId = data.id
                        let analyzeId = data.analyze
                        custom.map((yearData) => {
                          let newCustom = {}
                          yearData.values.map((data, index) => {
                            newCustom[data.key] = data.value
                          })
                          newCustom.year_for_data = new Date(
                            yearData.year
                          ).toISOString()
                          newCustom.projection_frequency =
                            firmBackground.projection_frequency
                          customScenarioDataCreate(
                            token,
                            newCustom,
                            analyzeId,
                            customId
                          ).catch((err) => reject())
                        })
                      })
                      .catch((err) => console.log(err))
                  }

                  resolve()
                })
                .catch((err) =>
                  reject({
                    errorType: 'api',
                    errorMessage: err,
                  })
                )
            } else {
              Promise.all([
                activeTab === 1 || activeTab === 2 || activeTab === 3
                  ? (realAnalysisCreate(
                      token,
                      newFinancialStatements,
                      analysisId
                    ),
                    realFxCreate(token, newFx, analysisId),
                    realMaturityCreate(token, newMaturity, analysisId))
                  : activeTab === 4
                  ? realBusinessCreate(token, newBusiness, analysisId)
                  : [],
              ])
                .then(() => {
                  if (activeTab === 5) {
                    customScenarioCreate(token, custom, analysisId)
                      .then(({ data }) => {
                        let customId = data.id
                        let analyzeId = data.analyze
                        custom.map((yearData) => {
                          let newCustom = {}
                          yearData.values.map((data, index) => {
                            newCustom[data.key] = data.value
                          })
                          newCustom.year_for_data = new Date(
                            yearData.year
                          ).toISOString()
                          newCustom.projection_frequency =
                            firmBackground.projection_frequency
                          customScenarioDataCreate(
                            token,
                            newCustom,
                            analyzeId,
                            customId
                          ).catch((err) => reject())
                        })
                      })

                      .catch((err) => console.log(err))
                  }

                  resolve()
                })
                .catch((err) =>
                  reject({
                    errorType: 'api',
                    errorMessage: err,
                  })
                )
            }
          })
          .catch((e) => console.log(e))
      }
    } else {
      reject({
        errorType: 'noToken',
      })
    }
  })
}

export default saveAnalysis
