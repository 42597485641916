import storeConstants from "redux/constants/actionTypes";



const initialState = {
    isOpen: true,
  };


const drawerOpenAndClosed = (state = initialState, action) => {
    switch (action.type) {
      case storeConstants.OPEN_DRAWER:
        return { ...state, isOpen: true };
      case storeConstants.CLOSE_DRAWER:
        return { ...state, isOpen: false };
      default:
        return state;
    }
  };
export default drawerOpenAndClosed