import React from 'react'
import { IntlProvider } from 'react-intl'
import en from 'messages/en.json'
import tr from 'messages/tr.json'
import cn from 'messages/cn.json'
import de from 'messages/de.json'
import es from 'messages/es.json'
import fr from 'messages/fr.json'
import pt from 'messages/pt.json'
import ru from 'messages/ru.json'
import sa from 'messages/sa.json'

const LangProvider = ({ children }) => {
  const initialState = {
    selectedLang: 'en',
  }

  function getConfig() {
    const ls = localStorage.getItem('selectedLang')
    if (ls) {
      try {
        return JSON.parse(ls)
      } catch (err) {
        console.error(err)
      }
    }
    localStorage.setItem('selectedLang', JSON.stringify(initialState))
    return initialState
  }
  const locale = getConfig().selectedLang
  const allMessages = {
    en,
    tr,
    cn,
    de,
    es,
    fr,
    pt,
    ru,
    sa,
  }

  return (
    <IntlProvider locale={locale} messages={allMessages[locale]}>
      {children}
    </IntlProvider>
  )
}

export default LangProvider
