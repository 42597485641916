import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useState } from 'react';
import dayjs from 'dayjs';
const CustomFrequencyPicker = () => {
  const [selectedFrequency, setSelectedFrequency] = useState('Month');
  const [selectedDate, setSelectedDate] = useState(dayjs().set('month', 11)); // Set default to December


  const monthsToDisable = [];
  const quartersToDisable = [0,1,3,4,6,7,9,10];
  const semiAnnualToDisable = [0,1,2,3,4,6,7,8,9,10];
  const annualToDisable = [0,1,2,3,4,5,6,7,8,9,10]; 

  const shouldDisableMonth = (date) => {
    console.log(date,"date")
    const month = date.month();
    console.log(month,"month")

    switch (selectedFrequency) {
      case 'Month':
        return monthsToDisable.includes(month);
      case 'Quarter':
        return quartersToDisable.includes(Math.floor(month));
      case 'Semi-Annual':
        return semiAnnualToDisable.includes(Math.floor(month));
      case 'Annual':
        return annualToDisable.includes(month);
      default:
        return false;
    }
  };

  return (
    <>
     <div>
        <select value={selectedFrequency} onChange={(e) => setSelectedFrequency(e.target.value)}>
          <option value="Month">Month</option>
          <option value="Quarter">Quarter</option>
          <option value="Semi-Annual">Semi-Annual</option>
          <option value="Annual">Annual</option>
        </select>
      </div>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={'Start Date'}
          views={['month', 'year']}
          value={selectedDate || dayjs()}
          shouldDisableMonth={shouldDisableMonth}
          onChange={setSelectedDate}
        />
    </LocalizationProvider>
    </>
  );
};

  
  export default CustomFrequencyPicker;
// const SpecificMonthsPicker = () => {
//     const [selectedDate, setSelectedDate] = useState(null);
  
//     const monthsToDisable = [2, 5, 8];
  
//     const shouldDisableMonth = (date) => {
//       const month = date.month();
//       return monthsToDisable.includes(month);
//     };
  
//     return (
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <div>
//           <DatePicker
//             label={'Start Date'}
//             views={["month",'year']}
//             value={selectedDate || dayjs()}
//             shouldDisableMonth={shouldDisableMonth}
//             onChange={setSelectedDate}
//           />
//         </div>
//       </LocalizationProvider>
//     );
//   };
  
//   export default SpecificMonthsPicker;

