import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import * as ReactBootStrap from 'react-bootstrap'
import * as analysisDataActions from 'redux/actions/analysisDataActions'
import { analysis } from 'redux/ApiCalls'
import { spinnerStyle } from 'components/LayoutStyles'
import Spinner from 'components/Spinner'
import ReactTooltip from 'react-tooltip'

const MaturityShortTerm = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [lastKeyPress, setLastKeyPress] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const {
    firmBackground,
    financial_statements,
    fx_position,
    maturity_short_term,
    business_plan,
    analysisResult,
  } = useSelector((state) => state.analysisDataReducer)
  const { token } = useSelector((state) => state.auth)

  // const handleInputChange = (value, key, index) => {
  //   const numberRegex = /[0-9]/g
  //   let newValue = value.replace(/,/g, '')
  //   if (newValue.match(numberRegex)?.length === newValue.length) {
  //     const newMaturityData = [...maturity_short_term]
  //     const findValue = newMaturityData[index].values.find(
  //       (item) => item.key === key
  //     )

  //     findValue.value = Number(newValue).toLocaleString('en-US')
  //     dispatch(analysisDataActions.maturityShortTerm(newMaturityData))
  //     setLastKeyPress(newMaturityData)
  //   }
  // }

  const handleInputChange = (value, key, index) => {
    const numberRegex = /^-?\d*\.?\d+$/ // Allows negative numbers and decimals

    let newValue = value.replace(/,/g, '')

    if (newValue === '' || newValue.match(numberRegex)) {
      const newMaturityData = [...maturity_short_term]
      const findValue = newMaturityData[index].values.find(
        (item) => item.key === key
      )

      findValue.value =
        newValue !== '' ? Number(newValue).toLocaleString('en-US') : ''
      dispatch(analysisDataActions.maturityShortTerm(newMaturityData))
      setLastKeyPress(newMaturityData)
    }
  }

  useEffect(() => {
    if (lastKeyPress) {
      const timeoutId = setTimeout(() => calculateAnalysis(), 1500)
      return () => clearTimeout(timeoutId)
    }
  }, [maturity_short_term, lastKeyPress])

  const trueOrFalse = (() => {
    if (firmBackground.industry === 'Finance & Insurance') {
      return false
    }
    return true
  })()

  const calculateAnalysis = () => {
    setIsLoading(true)
    const postData = {
      analysis_data: {
        financial: {},
        fx: {},
        maturity: {},
        business_plan: {},
        scenario: {},
      },
      firm_background: firmBackground,
      is_analysis_real: trueOrFalse,
      enable_custom_scenario: false,
    }
    financial_statements.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key].push(newValue)
        })
      }
    })

    fx_position.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key].push(newValue)
        })
      }
    })

    maturity_short_term.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key].push(newValue)
        })
      }
    })

    business_plan.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key].push(newValue)
        })
      }
    })

    if (analysisResult?.scenarios) {
      analysisResult.scenarios.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    } else {
      initial_custom_scenario.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    }
    analysis(token, postData)
      .then(({ data }) => {
        dispatch(analysisDataActions.fetchRealAnalysis(data))
        setIsLoading(false)
      })
      .catch((err) => err)
  }

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const spinner = (
    <div style={spinnerStyle}>
      {' '}
      {isLoading ? (
        <ReactBootStrap.Spinner animation="border" />
      ) : (
        <span></span>
      )}
    </div>
  )

  const table = (
    <div className="financial-main">
      <table className="financial-main-table">
        <thead>
          <tr className="financial-main-table-tr">
            <th className="fixed-row"></th>

            {maturity_short_term.map((item, index) => (
              <th key={index} scope="col">
                {new Date(item.year).toLocaleString('en-US', {
                  year: 'numeric',
                }) +
                  '-' +
                  new Date(item.year).toLocaleString('en-US', {
                    month: 'short',
                  })}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="financial-main-table-tbody">
          {maturity_short_term[0]?.values.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td
                className="financial-main-table-fixed-row"
                data-tip={
                  row.label === 'Cash And Banks'
                    ? intl.formatMessage({
                        id: 'tooltip_cash_and_banks',
                      })
                    : row.label === 'Debt Securities'
                    ? intl.formatMessage({
                        id: 'tooltip_equity_securities',
                      })
                    : row.label === 'Equity Securities'
                    ? intl.formatMessage({
                        id: 'tooltip_equity_securities',
                      })
                    : row.label === 'Debt'
                    ? intl.formatMessage({
                        id: 'Debt',
                      })
                    : ''
                }
              >
                <ReactTooltip />
                {intl.formatMessage({
                  id: row.key,
                })}
              </td>
              {maturity_short_term.map((yearData, columnIndex) => (
                <>
                  <td key={columnIndex}>
                    {/* <input
                      type="text"
                      onChange={(e) =>
                        handleInputChange(e.target.value, row.key, columnIndex)
                      }
                      defaultValue="0"
                      value={yearData.values[rowIndex].value.toLocaleString('en-US')}
                      className="financial-main-table-input"
                    /> */}
                    <input
                      type="text"
                      onChange={(e) =>
                        handleInputChange(e.target.value, row.key, columnIndex)
                      }
                      value={yearData.values[rowIndex].value.toLocaleString(
                        'en-US'
                      )}
                      className="financial-main-table-input"
                      pattern="^-?\d*\.?\d+" // Allows negative numbers and decimals
                    />
                  </td>
                </>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  return (
    <div>
      {isLoading ? <Spinner /> : ''}
      <div className="analysis-table-header-container">
        <div className="analysis-table-header-container-sub">
          <div className="analysis-table-header-container-table">{table}</div>
        </div>
      </div>
    </div>
  )
}

export default MaturityShortTerm
