import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  firmBackgroundCreate,
  realAnalysisCreate,
  realBusinessCreate,
  realFxCreate,
  realMaturityCreate,
  financialAnalysisCreate,
  financialBusinessCreate,
  financialFxCreate,
  financialMaturityCreate,
  createFirm,
  analysisCreate,
  loanRequestCreate,
  equityRequestCreate,
} from '../redux/ApiCalls'

import { useIntl } from 'react-intl'
import { login } from '../redux/ApiCalls'
import storeConstants from '../redux/constants/actionTypes'
import { toast } from 'react-toastify'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
// import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Link from '@mui/material/Link'
const theme = createTheme()

const Login = (props) => {
  const intl = useIntl()

  const { register, handleSubmit } = useForm()

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const {
    firmBackground,
    financial_statements,
    fx_position,
    maturity_short_term,
    business_plan,
    analysisResult,
    saved_analysis,
  } = useSelector((state) => state.analysisDataReducer)

  let custom = analysisResult?.scenarios

  const { addLoan, saveLoan } = useSelector((state) => state.loanRequest)

  const { addEquity, saveEquity } = useSelector((state) => state.equityRequest)

  const onSubmit = (data) => {
    login({
      email: data.email,
      password: data.password,
    })
      .then((res) => {
        if (res.data.access) {
          dispatch({
            type: storeConstants.LOGIN_SUCCESS,
            payload: res.data.access,
          })
          toast.success(
            intl.formatMessage({
              id: 'TOAST_MESSAGES.LOGIN.SUCCESS',
            })
          )
          let token = res.data.access
          if (saveLoan) {
            loanRequestCreate(equityRequestCreate, token, addLoan)
              .then((res) => {
                toast.success('Loan request created successfully.')
              })
              .catch((err) => {
                toast.error('Loan request creation failed.')
              })
          }
          if (saveEquity) {
            equityRequestCreate(token, addEquity)
              .then((res) => {
                toast.success('Equity request created successfully.')
              })
              .catch((err) => {
                toast.error('Equity request creation failed.')
              })
          }
          if (saved_analysis) {
            createFirm(res.data.access, {
              ...firmBackground,
              foreCastStartDate: firmBackground.foreCastStartDate / 1000,
              decemberOfLastYear: firmBackground.decemberOfLastYear / 1000,
              foreCastEndDate: firmBackground.foreCastEndDate / 1000,
              fiscalYear: firmBackground.fiscalYear / 1000,
            })
              .then(({ data }) => {
                toast.success('Firm Created')
                let firmSlug = data.slug
                let token = res.data.access
                analysisCreate(token, firmSlug)
                  .then(({ data }) => {
                    toast.success('Analysis Created')
                    let analysisId = data.id

                    if (firmBackground.industry === 'Finance & Insurance') {
                      financialAnalysisCreate(
                        token,
                        financial_statements,
                        analysisId
                      )
                      financialFxCreate(token, fx_position, analysisId)
                      financialMaturityCreate(
                        token,
                        maturity_short_term,
                        analysisId
                      )
                      financialBusinessCreate(token, business_plan, analysisId)
                    } else {
                      realAnalysisCreate(
                        token,
                        financial_statements,
                        analysisId
                      )
                      realFxCreate(token, fx_position, analysisId)
                      realMaturityCreate(token, maturity_short_term, analysisId)
                      realBusinessCreate(token, business_plan, analysisId)
                    }
                    // firmBackgroundCreate(token, firmBackground)
                    customScenarioCreate(token, custom, analysisId).then(
                      ({ data }) => {
                        let customId = data.id
                        let analyzeId = data.analyze
                        custom.map((yearData) => {
                          let newCustom = {}
                          yearData.values.map((data, index) => {
                            newCustom[data.key] = data.value
                          })
                          newCustom.year_for_data = new Date(
                            yearData.year
                          ).toISOString()
                          newCustom.projection_frequency =
                            firmBackground.projection_frequency
                          customScenarioDataCreate(
                            token,
                            newCustom,
                            analyzeId,
                            customId
                          ).catch((err) => {
                            console.log(err)
                          })
                        })
                      }
                    )
                  })
                  .catch((err) => {
                    console.log(err)
                  })
              })
              .catch((err) => {
                console.log(err)
                toast.error(err?.response?.detail)
              })
          }
          navigate('/myfirm')
        }
      })
      .catch((err) => {
        toast.error(
          intl.formatMessage({
            id: 'TOAST_MESSAGES.LOGIN.ERROR',
          })
        )
      })
  }
  const onClick = () => {
    navigate('/register')
  }
  const onClickForgotPassword = () => {
    navigate('/reset')
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="main-login">
          <Container component="main" maxWidth="xs">
            <a
              className="button-equlion"
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textDecoration: 'none',
              }}
            >
              <span style={{ color: 'white' }}>Equ</span>
              <span
                className="header-menu-logo-lion"
                style={{ color: '#f73681' }}
              >
                <span style={{ fontSize: '40px' }}>l</span>ion
              </span>
            </a>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Avatar sx={{ m: 1, background: 'Transparent' }}></Avatar>
              <Box
                className="auth_form"
                component="form"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                sx={{ mt: 3 }}
              >
                <svg className="signup-sides">
                  <line
                    className="top-right first"
                    x1="50%"
                    x2="100%"
                    y1="0"
                    y2="0"
                  />
                  <line
                    className="top-left first"
                    x1="50%"
                    x2="0"
                    y1="0"
                    y2="0"
                  />
                  <line
                    className="right second"
                    x1="100%"
                    x2="100%"
                    y1="0%"
                    y2="100%"
                  />
                  <line
                    className="left second"
                    x1="0"
                    x2="0"
                    y1="0"
                    y2="100%"
                  />
                  <line
                    className="bottom-left third"
                    x1="0"
                    x2="50%"
                    y1="100%"
                    y2="100%"
                  />
                  <line
                    className="bottom-right third"
                    x1="100%"
                    x2="50%"
                    y1="100%"
                    y2="100%"
                  />
                </svg>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      autoComplete="given-name"
                      name="email"
                      type="email"
                      required
                      fullWidth
                      size="small"
                      id="email"
                      {...register('email')}
                      label={intl.formatMessage({
                        id: 'REGISTER.EMAIL',
                      })}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      size="small"
                      name="password"
                      label={intl.formatMessage({
                        id: 'REGISTER.PASSWORD',
                      })}
                      type="password"
                      id="password"
                      {...register('password')}
                      autoComplete="current-password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  size="small"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {intl.formatMessage({
                    id: 'LOGIN.TITLE',
                  })}
                </Button>

                <Grid container>
                  <Grid item className="login_common_text">
                    <Button>
                      <Link onClick={onClickForgotPassword} variant="body2">
                        {intl.formatMessage({
                          id: 'LOGIN.TEXT_PASSWORD_FORGOT',
                        })}
                      </Link>
                    </Button>
                    <Button>
                      <Link onClick={onClick} variant="body2">
                        {intl.formatMessage({
                          id: 'LOGIN.TEXT',
                        })}
                      </Link>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </div>
      </ThemeProvider>
    </>
  )
}

export default Login
