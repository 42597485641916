import storeConstants from 'redux/constants/actionTypes'

const initialState = {
  token: null,
  isAuthenticated: null,
  resetTokenAnduidb64: {
    resetToken: null,
    uidb64: null
  }
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case storeConstants.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
        isAuthenticated: true,
      }
    case storeConstants.RESET_TOKEN:
      return {
        ...state,
        token: action.payload,
      }
    case storeConstants.PASSWORD_RESET_TOKEN_AND_UIDB64:
      return {
        ...state,
        resetTokenAnduidb64: action.payload,
      }
    default:
      return state
  }
}
export default auth
