import storeConstants from '../constants/actionTypes'

const inintialState = {
  key: new Date(),
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: [],
  activeTab: true,
  activeTab1: true,
  indexTab2: true,
  indexTab: true,

  indexTab3: true,
  indexTab4: true,


  activeTab2: true,
  activeTab3: true,
  activeTab4: true,
  activeTab5: true,
  activeTab6: true,


}

const joyRide = (state = inintialState, action) => {
  switch (action.type) {
    case storeConstants.STDATA:
      return { ...state, steps: action.payload };
    case storeConstants.ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case storeConstants.ACTIVE_TAB1:
      return { ...state, activeTab1: action.payload };
    case storeConstants.ACTIVE_TAB2:
      return { ...state, activeTab2: action.payload };
    case storeConstants.ACTIVE_TAB3:
      return { ...state, activeTab3: action.payload };
    case storeConstants.ACTIVE_TAB4:
      return { ...state, activeTab4: action.payload };
    case storeConstants.ACTIVE_TAB5:
      return { ...state, activeTab5: action.payload };
    case storeConstants.ACTIVE_TAB6:
      return { ...state, activeTab6: action.payload };
    case storeConstants.INDEX_TAB:
      return { ...state, indexTab2: action.payload };
    case storeConstants.INDEX_TAB4:
      return { ...state, indexTab3: action.payload };
      case storeConstants.INDEX_TAB5:
        return { ...state, indexTab4: action.payload };
    case storeConstants.INDEX_TAB1:
      return { ...state, indexTab: action.payload };


    case storeConstants.START:
      return { ...state, run: true };
    case storeConstants.RESET:
      return { ...state, stepIndex: 0 };
    case storeConstants.STOP:
      return { ...state, run: false };
    case storeConstants.NEXT_OR_PREV:
      return { ...state, ...action.payload };
    case storeConstants.RESTART:
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
}

export default joyRide
