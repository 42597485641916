import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Bar, Line } from 'react-chartjs-2'

import { useIntl } from 'react-intl'

const indicatorsBackgroundColor = [
  'rgba(255, 99, 132, 4)',
  'rgba(54, 162, 235, 4)',
  'rgba(255, 206, 86, 4)',
  'rgba(75, 192, 192, 4)',
  'rgba(153, 102, 255, 4)',
  'rgba(255, 159, 64, 4)',
  'rgba(255, 99, 132, 4)',
]

const FinancialAnalysisReport = () => {
  const [chartDatas, setChartDatas] = useState({})
  const [chartIndicatorsDatas, setChartIndicatorsDatas] = useState({})
  const intl = useIntl()
  const { analysisResult, firmBackground } = useSelector(
    (state) => state.analysisDataReducer
  )

  const {
    custom_financial_statements,
    financial_statements,
    enable_custom_scenario,
  } = analysisResult

  const myfirm = useSelector((state) => state.myfirm.myfirm)

  const labels = []

  analysisResult.financial_statements.map((datas) => {
    labels.push(datas?.year)
  })

  const financialAnalysisFieldTable = (keyArrays) => {
    let newChartData = {}

    keyArrays.map((key) => {
      const dataSet = []

      const baselineDataSet = {
        backgroundColor: indicatorsBackgroundColor,
        fill: false,
        label: 'Baseline Scenario',
        title: 'test',
        chartType: 'line',
        data: [],
      }
      const customDataSet = {
        backgroundColor: indicatorsBackgroundColor,
        fill: false,
        label: 'Custom Scenario',
        title: 'test',
        chartType: 'line',
        data: [],
      }
      analysisResult.financial_statements.map((datas) => {
        const totalAsetValue = datas.values.find((item) => item.key === key)
        baselineDataSet.data.push(totalAsetValue.value)
      })
      dataSet.push(baselineDataSet)

      if (enable_custom_scenario) {
        analysisResult.custom_financial_statements.map((datas) => {
          const totalAsetValue = datas.values.find((item) => item.key === key)
          customDataSet.data.push(totalAsetValue.value)
        })
        dataSet.push(customDataSet)
      }

      newChartData[key] = { labels, datasets: dataSet }
    })
    setChartDatas(newChartData)
  }

  const indicatorsTables = (keyArrays) => {
    let newChartData = {}

    keyArrays.map((key) => {
      const dataSet = []

      const baselineDataSet = {
        backgroundColor: indicatorsBackgroundColor,
        fill: false,
        label: 'Baseline Scenario',
        title: 'test',
        chartType: 'line',
        data: [],
      }
      const customDataSet = {
        backgroundColor: indicatorsBackgroundColor,
        fill: false,
        label: 'Custom Scenario',
        title: 'test',
        chartType: 'line',
        data: [],
      }
      analysisResult.indicators.map((datas) => {
        const indicatorsData = datas.values.find((item) => item.label === key)
        baselineDataSet.data.push(indicatorsData.value)
      })
      dataSet.push(baselineDataSet)

      if (enable_custom_scenario) {
        analysisResult.custom_indicators.map((datas) => {
          const indicatorsData = datas.values.find((item) => item.label === key)
          customDataSet.data.push(indicatorsData.value)
        })
        dataSet.push(customDataSet)
      }

      newChartData[key] = { labels, datasets: dataSet }
    })
    setChartIndicatorsDatas(newChartData)
  }

  useEffect(() => {
    financialAnalysisFieldTable(['total_assets', 'net_income'])
  }, [])

  useEffect(() => {
    indicatorsTables([
      'Capital Adequacy Ratio(CAR)',
      'Liquidity Ratio',
      'Growth Rate Of Loans',
      'NPL Ratio',
      'Specific Reserve Ratio',
      'Loans to Deposits',
      'Leverage',
      'Net Interest Margin',
      'Return on Assets(ROA)',
      'Return on Equity(ROE)',
    ])
  }, [])

  const table = (
    <div>
      <table>
        <thead>
          <tr>
            <th className="fixed-row" style={{ width: '300px' }}>
              <span
                className="financial-main-table-fixed-row-title"
                style={{ color: '#012169' }}
              >
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE',
                })}
              </span>
            </th>
            {analysisResult?.financial_statements?.map(
              (yearData, columnIndex) => {
                yearData.year.split('-')
                const date = new Date(
                  Number(yearData.year.split('-')[0]),
                  Number(yearData.year.split('-')[1]),
                  0
                )
                return (
                  <th scope="col" key={columnIndex}>
                    {date.toLocaleString('en-US', {
                      year: 'numeric',
                    }) +
                      '-' +
                      date.toLocaleString('en-US', {
                        month: 'short',
                      })}
                  </th>
                )
              }
            )}
          </tr>
        </thead>
        <tbody>
          {financial_statements[0]?.values.slice(0, 14).map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td
                className={`fixed-row ${
                  row.label === 'Total Assets' ||
                  row.label === 'Total Liabilities And Equity'
                    ? 'fw-bold'
                    : ''
                }`}
              >
                {intl.formatMessage({
                  id: row.key,
                })}
              </td>
              {analysisResult?.financial_statements?.map(
                (yearData, columnIndex) => (
                  <>
                    <td key={columnIndex}>
                      <input
                        type="text"
                        defaultValue={0}
                        value={yearData.values[rowIndex].value}
                        disabled
                      />
                    </td>
                  </>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th className="fixed-row" style={{ width: '300px' }}>
              <span style={{ color: '#012169' }}>
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE',
                })}
              </span>
            </th>
            {analysisResult?.financial_statements?.map(
              (yearData, columnIndex) => {
                yearData.year.split('-')
                const date = new Date(
                  Number(yearData.year.split('-')[0]),
                  Number(yearData.year.split('-')[1]),
                  0
                )
                return (
                  <th scope="col" key={columnIndex}>
                    {date.toLocaleString('en-US', {
                      year: 'numeric',
                    }) +
                      '-' +
                      date.toLocaleString('en-US', {
                        month: 'short',
                      })}
                  </th>
                )
              }
            )}
          </tr>
        </thead>
        <tbody>
          {financial_statements[0]?.values
            .slice(14, financial_statements[0].values.length)
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className={row.label === 'Net Income' ? 'fw-bold' : ''}>
                  {row.label}
                </td>
                {analysisResult?.financial_statements?.map(
                  (yearData, columnIndex) => (
                    <>
                      <td key={columnIndex}>
                        <input
                          type="text"
                          defaultValue={0}
                          value={yearData?.values[rowIndex + 14].value}
                          disabled
                        />
                      </td>
                    </>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
  const customTable = (
    <div>
      <table>
        <thead>
          <tr>
            <th style={{ width: '300px' }}>
              <span
                className="financial-main-table-fixed-row-title"
                style={{ color: '#012169' }}
              >
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE',
                })}
              </span>
            </th>
            {custom_financial_statements?.map((yearData, columnIndex) => {
              yearData.year.split('-')
              const date = new Date(
                Number(yearData.year.split('-')[0]),
                Number(yearData.year.split('-')[1]),
                0
              )
              return (
                <th scope="col" key={columnIndex}>
                  {date.toLocaleString('en-US', {
                    year: 'numeric',
                  }) +
                    '-' +
                    date.toLocaleString('en-US', {
                      month: 'short',
                    })}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {custom_financial_statements[0]?.values
            .slice(0, 11)
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td
                  className={
                    row.label === 'Total Assets' ||
                    row.label === 'Total Liabilities And Equity'
                      ? 'fw-bold'
                      : ''
                  }
                >
                  {row.label}
                </td>
                {analysisResult?.custom_financial_statements?.map(
                  (yearData, columnIndex) => (
                    <>
                      <td key={columnIndex}>
                        <input
                          type="text"
                          defaultValue={0}
                          value={yearData.values[rowIndex].value}
                          disabled
                        />
                      </td>
                    </>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th style={{ width: '300px' }}>
              <span style={{ color: '#012169' }}>
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE',
                })}
              </span>
            </th>
            {custom_financial_statements?.map((yearData, columnIndex) => {
              yearData.year.split('-')
              const date = new Date(
                Number(yearData.year.split('-')[0]),
                Number(yearData.year.split('-')[1]),
                0
              )
              return (
                <th scope="col" key={columnIndex}>
                  {date.toLocaleString('en-US', {
                    year: 'numeric',
                  }) +
                    '-' +
                    date.toLocaleString('en-US', {
                      month: 'short',
                    })}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {custom_financial_statements[0]?.values
            .slice(12, custom_financial_statements[0].values.length)
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className={row.label === 'Net Income' ? 'fw-bold' : ''}>
                  {row.label}
                </td>
                {analysisResult?.custom_financial_statements?.map(
                  (yearData, columnIndex) => (
                    <>
                      <td key={columnIndex}>
                        <input
                          type="text"
                          defaultValue={0}
                          value={yearData?.values[rowIndex + 12].value}
                          disabled
                        />
                      </td>
                    </>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
  return (
    <div>
      {analysisResult?.financial_statements &&
      chartIndicatorsDatas['Capital Adequacy Ratio(CAR)'] ? (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-6">
              <h3>
                {intl.formatMessage({
                  id: 'FINANCIAL_CHART.TOTAL_ASSETS',
                })}
              </h3>
              <Line data={chartDatas.total_assets} />
            </div>
            <div className="col-6">
              <h3>
                {intl.formatMessage({
                  id: 'FINANCIAL_CHART.NET_INCOME',
                })}
              </h3>
              <Line data={chartDatas.net_income} />
            </div>
          </div>
          <hr />
          <div className="row justify-content-center">
            <div className="col-6">
              <h3>
                {intl.formatMessage({
                  id: 'FINANCIAL_CHART.CAR',
                })}
              </h3>
              <Bar data={chartIndicatorsDatas['Capital Adequacy Ratio(CAR)']} />
            </div>
            <div className="col-6">
              <h3>
                {intl.formatMessage({
                  id: 'FINANCIAL_CHART.LIQUIDITY_RATIO',
                })}
              </h3>
              <Bar data={chartIndicatorsDatas['Liquidity Ratio']} />
            </div>
          </div>
          <hr />
          <div className="row justify-content-center">
            <div className="col-6">
              <h3>
                {intl.formatMessage({
                  id: 'FINANCIAL_CHART.GROWTH_RATE_OF_LOANS',
                })}
              </h3>
              <Bar data={chartIndicatorsDatas['Growth Rate Of Loans']} />
            </div>
            <div className="col-6">
              <h3>
                {intl.formatMessage({
                  id: 'FINANCIAL_CHART.NPL_RATIO',
                })}
              </h3>
              <Bar data={chartIndicatorsDatas['NPL Ratio']} />
            </div>
          </div>
          <hr />
          <div className="row justify-content-center">
            <div className="col-6">
              <h3>
                {intl.formatMessage({
                  id: 'FINANCIAL_CHART.SPECIFIC_RESERVE_RATIO',
                })}
              </h3>
              <Bar data={chartIndicatorsDatas['Specific Reserve Ratio']} />
            </div>
            <div className="col-6">
              <h3>
                {intl.formatMessage({
                  id: 'FINANCIAL_CHART.LOANS_TO_DEPOSITS',
                })}
              </h3>
              <Bar data={chartIndicatorsDatas['Loans to Deposits']} />
            </div>
          </div>
          <hr />
          <div className="row justify-content-center">
            <div className="col-6">
              <h3>
                {intl.formatMessage({
                  id: 'FINANCIAL_CHART.LEVERAGE',
                })}
              </h3>
              <Bar data={chartIndicatorsDatas['Leverage']} />
            </div>
            <div className="col-6">
              <h3>
                {intl.formatMessage({
                  id: 'FINANCIAL_CHART.NET_INTEREST_MARGIN',
                })}
              </h3>
              <Bar data={chartIndicatorsDatas['Net Interest Margin']} />
            </div>
          </div>
          <hr />
          <div className="row justify-content-center">
            <div className="col-6">
              <h3>
                {intl.formatMessage({
                  id: 'FINANCIAL_CHART.ROA',
                })}
              </h3>
              <Bar data={chartIndicatorsDatas['Return on Assets(ROA)']} />
            </div>
            <div className="col-6">
              <h3>
                {intl.formatMessage({
                  id: 'FINANCIAL_CHART.ROE',
                })}
              </h3>
              <Bar data={chartIndicatorsDatas['Return on Equity(ROE)']} />
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      <br />
      <br />
      <br />
      {analysisResult ? (
        <div className="container">
          <div className="analysis-table-header-container">
            <div className="analysis-table-header-container-sub">
              <div className="analysis-table-header-container-sub-text">
                <h1>
                  {intl.formatMessage({
                    id: 'FINANCIAL_CHART.TITLE',
                  })}
                </h1>
              </div>
            </div>

            <div className="analysis-table-header-container-sub">
              <div className="analysis-table-header-container-table">
                {table}
              </div>
            </div>
          </div>
          <br />
          <div className="analysis-table-header-container">
            <div className="analysis-table-header-container-sub">
              <div className="analysis-table-header-container-sub-text">
                <h1>
                  {intl.formatMessage({
                    id: 'FINANCIAL_CHART.CUSTOM_TABLE_TITLE',
                  })}
                </h1>
              </div>
              <div className="analysis-table-header-container-sub">
                <div className="analysis-table-header-container-table">
                  {customTable}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
export default FinancialAnalysisReport
